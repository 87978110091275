import {useEffect, useRef, useState} from "react";

export function useClickOutside(handler: () => void) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const maybeHandler = (event: { target: any; }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener('mousedown', maybeHandler)
    return () => document.removeEventListener('mousedown', maybeHandler)
  })
  return ref;
}


/**
 * Useful for tracking useEffect call reasons.
 *
 * Example usage:
 *
 * const prev = usePrevious({v1, v2, v3});
 *
 * useEffect(() => {
 *   if (prev.v1 !== v1) console.log('v1 changed')
 *   if (prev.v2 !== v2) console.log('v2 changed')
 * }, [v1, v2])
 *
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


/**
 * Useful for getting screen size.
 *
 * Returns screen width and height in px, that updates on resize.
 */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
