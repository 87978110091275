import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  AppNavbarBrand,
  AppSidebarToggler
} from '@coreui/react';
import logo from '../../assets/img/brand/logo-1-white.svg'
import sygnet from '../../assets/img/brand/logo-1-icon-white.svg'
import {Link, withRouter} from "react-router-dom";
import {deleteAuthToken} from "../../Environment";
import {withUser} from "../../context/UserContext";
import UserCombinedMenu from "../../views/Users/Navbar/UserCombinedMenu";
import SettingsModal from "../../views/Users/Settings/SettingsModal"
import {handleBrandChange} from "../changeBrand";
import AddCompanyModal from "../../components/AddCompanyModal";
import {ourToast} from "../../ui-kit/src/atoms/Toast";
import ManageApiKeysModal from "../../components/ManageApiKeysModal";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSettingsModalOpen: false,
      isAddCompanyModalOpen: false,
      isManageApiKeysModalOpen: false
    }
  }

  onSettingsClick = () => {
    this.setState({isSettingsModalOpen: !this.state.isSettingsModalOpen});
  }

  onAddCompanyClick = (e) => {
    e && e.preventDefault();
    if(this.props.user?.company?.id) {
      this.setState({isAddCompanyModalOpen: !this.state.isAddCompanyModalOpen});
    } else {
      this.setState({isAddCompanyModalOpen: false});
      ourToast("error", "Error adding a new brand.");
    }
  }

  toggleManageApiKeysModal = () => {
    if(this.props.user?.brand?.id) {
      this.setState({isManageApiKeysModalOpen: !this.state.isManageApiKeysModalOpen})
    } else {
      this.setState({isManageApiKeysModalOpen: false})
      ourToast("error", "Error fetching API Keys");
    }
  }

  onLogOutClick = () => {
    deleteAuthToken();
    this.props.history.push("/login");
  }

  render() {
    const {user} = this.props;
    return (
        <React.Fragment>
          <AppSidebarToggler className="d-lg-none" display="md" mobile/>
          <AppNavbarBrand
            tag={(props) => {
              return <Link {...props} />
            }}
              to={'/product/my-products'}
              full={{src: logo, width: 96, height: 35, alt: 'Vuuh Logo'}}
              minimized={{src: sygnet, width: 30, height: 35, alt: 'Vuuh Logo'}}
          />
          {user.brand && <UserCombinedMenu
                          name={user.firstName + " " + user.lastName}
                          email={user.email}
                          displaySettings={user.brand}
                          handleSettings={this.onSettingsClick}
                          hasChangeDetailsPermission={user.brand.hasPermissions.changeBrand}
                          hasManageUsersPermission={user.brand.hasPermissions.manageBrandUsers}
                          handleLogOut={this.onLogOutClick}
                          handleAddCompany={this.onAddCompanyClick}
                          canAddCompany={true}
                          toggleManageApiKeysModal={this.toggleManageApiKeysModal}
                          companies={user.brandSet.edges.map(({node}) => {
                            return {name: node.name, id: node.id}
                          })}
                          currentCompany={{name: user.brand.name, id: user.brand.id}}
                          handleCompanyChange={(company) => handleBrandChange(company)}
          />}
          {this.state.isSettingsModalOpen ?
            <SettingsModal isAdmin={this.props.user.brand.hasPermissions.changeBrand}
                           isOpen={this.state.isSettingsModalOpen}
                           toggle={this.onSettingsClick}/> : null}
          {this.state.isAddCompanyModalOpen ?
            <AddCompanyModal isModalOpen={this.state.isAddCompanyModalOpen}
                             toggle={this.onAddCompanyClick}
                             id={user?.company?.id}
            /> : null}
          {this.state.isManageApiKeysModalOpen ?
            <ManageApiKeysModal isModalOpen={this.state.isManageApiKeysModalOpen}
                                toggle={this.toggleManageApiKeysModal}
                                brandId={user?.brand?.id}
            /> : null}
        </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default withUser(withRouter(DefaultHeader));
