import React, {InputHTMLAttributes, LabelHTMLAttributes, useEffect, useRef, useState} from 'react';
import {getClassNames} from "../classNameUtils";
import styles from "./Checkbox.module.scss"

interface CustomCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: () => void
  label?: string | JSX.Element
  indeterminate?: boolean
  className?: string
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
}

function Checkbox({onChange, checked, label, labelProps, indeterminate = false, className, ...props}: CustomCheckboxProps) {
  const [isHovered, setIsHovered] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate, onChange])

  // We render the input as readonly because of how custom checkboxes are implemented in HTML5+CSS3
  //
  // How it works: it hides the actual <input> and renders another styled thing on top of it
  // whose display is influenced by the input state. But the actual events and interaction is done
  // with the wrapper element (div.custom-control.custom-checkbox).
  //
  // We put the readOnly on the input to suppress a warning in the console.
  return <div className={getClassNames([
    {className: styles.checkboxContainer, condition: true},
    {className: className, condition: !!className}
  ])}
              onClick={onChange}>
    <input type="checkbox"
           className={getClassNames([
             {className: styles.input, condition: true},
             {className: styles.disabled, condition: props.disabled}
           ])}
           checked={checked}
           readOnly
           ref={inputRef}
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}
           {...props}/>
    <label className={getClassNames([
      {className: styles.label, condition: true},
      {className: styles.disabled, condition: props.disabled},
      {className: styles.labelHover, condition: isHovered}
    ])} {...labelProps}>
      {label}
    </label>
  </div>
}

export default Checkbox;
