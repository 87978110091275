// product selection actions
const SELECT_ALL = 'SELECT_ALL';
const DESELECT_ALL = 'DESELECT_ALL';
const SELECT_PRODUCT = 'SELECT_PRODUCT';
const SELECT_PRODUCTS_FROM_ORDER_CONFIRMATION = 'SELECT_PRODUCTS_FROM_ORDER_CONFIRMATION';
const DESELECT_PRODUCT = 'DESELECT_PRODUCT';
const SELECT_VISIBLE = 'SELECT_VISIBLE';
const DESELECT_VISIBLE = 'DESELECT_VISIBLE';

// product table visibility
const SET_VISIBLE_PRODUCTS = 'SET_VISIBLE_PRODUCTS';


export default {
  SELECT_ALL,
  DESELECT_ALL,
  SELECT_PRODUCT,
  SELECT_PRODUCTS_FROM_ORDER_CONFIRMATION,
  DESELECT_PRODUCT,
  SELECT_VISIBLE,
  DESELECT_VISIBLE,
  SET_VISIBLE_PRODUCTS,
}
