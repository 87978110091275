/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateBrandIngestTokenMutationInput = {
    id: string;
    clientMutationId?: string | null | undefined;
};
export type CreateBrandApiKeyMutationVariables = {
    input: CreateBrandIngestTokenMutationInput;
};
export type CreateBrandApiKeyMutationResponse = {
    readonly createBrandIngestToken: {
        readonly clientMutationId: string | null;
        readonly instance: {
            readonly id: string;
            readonly token: string;
            readonly addTags: ReadonlyArray<string>;
        } | null;
    } | null;
};
export type CreateBrandApiKeyMutation = {
    readonly response: CreateBrandApiKeyMutationResponse;
    readonly variables: CreateBrandApiKeyMutationVariables;
};



/*
mutation CreateBrandApiKeyMutation(
  $input: CreateBrandIngestTokenMutationInput!
) {
  createBrandIngestToken(input: $input) {
    clientMutationId
    instance {
      id
      token
      addTags
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBrandIngestTokenMutationPayload",
    "kind": "LinkedField",
    "name": "createBrandIngestToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandIngestTokenNode",
        "kind": "LinkedField",
        "name": "instance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addTags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateBrandApiKeyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateBrandApiKeyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "caaba42a3ab1c01c7d21c93f0bcaf5c7",
    "id": null,
    "metadata": {},
    "name": "CreateBrandApiKeyMutation",
    "operationKind": "mutation",
    "text": "mutation CreateBrandApiKeyMutation(\n  $input: CreateBrandIngestTokenMutationInput!\n) {\n  createBrandIngestToken(input: $input) {\n    clientMutationId\n    instance {\n      id\n      token\n      addTags\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3311ddfc6d47d20616185b53352be3bf';
export default node;
