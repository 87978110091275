import React, {useState} from "react";
import GrayModal from "../../../dataDisplay/GrayModal";
import {noop} from "../../../commons/misc";
import {Row} from "reactstrap";
import styles from "./NewPasswordModal.module.scss"
import {Environment} from "relay-runtime";
import {ModalErrorAlert} from "../../../commons/errors";
import ChangePasswordWithTokenMutation from "../../../mutations/ChangePasswordWithTokenMutation";
import {useLocation} from "react-router-dom";
import Input from '../../../atoms/Input';

type TriggerPasswordResetModalProps = {
  environment: Environment
  scope: string
  onSend: () => void
}

const PASSWORDS_DOES_NOT_MATCH = "Password and Confirm Password does not match"
const TOKEN_MISSING = "Invalid password reset request. Are you sure you got this link via a valid Vuuh email?"

const ERROR_DICTIONARY = {
  "users.invalid_reset_password_token": "This reset password link is invalid",
  "users.password_too_short": "The password is too short. It should be at least 8 characters long",
  "users.password_not_strong_enough": "The password is too weak. " +
    "Make sure it is at least 8 characters long, it contains at least one number and one special character",
}

const translator = (error: string | null) => {
  if (ERROR_DICTIONARY[error as keyof typeof ERROR_DICTIONARY]) {
    return ERROR_DICTIONARY[error as keyof typeof ERROR_DICTIONARY]
  } else if (error === PASSWORDS_DOES_NOT_MATCH) {
    return PASSWORDS_DOES_NOT_MATCH
  } else if (error === TOKEN_MISSING) {
    return TOKEN_MISSING
  } else {
    return "Something went wrong"
  }
}

export default function NewPasswordModal({environment, onSend}: TriggerPasswordResetModalProps) {
  let [newPassword, setNewPassword] = useState('')
  let [confirmPassword, setConfirmPassword] = useState('')
  let [error, setError] = useState<string | Error>('')
  let [displayPasswordReqs, setDisplayPasswordReqs] = useState(false)

  const location = useLocation();
  let token = new URLSearchParams(location.search).get("token")

  const onSubmit = () => {
    if (newPassword === confirmPassword && token) {

      ChangePasswordWithTokenMutation(
        environment,
        {password: newPassword, token: token},
        () => {
          onSend()
        },
        (error) => {
          setError(error);
        }
      )
    } else if (token && newPassword !== confirmPassword) {
      setError(PASSWORDS_DOES_NOT_MATCH)
    } else {
      setError(TOKEN_MISSING)
    }
  }

  const onPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && newPassword)
      onSubmit()
  }

  return <GrayModal
    isOpen={true}
    toggle={noop}
    style={{width: "34rem"}}
    title={"New password"}
    primaryHeaderButton={{text: "Continue", onClick: onSubmit, isDisabled: !newPassword || !confirmPassword}}
    bodyContent={
      <div className={"my-4 mx-5"}>
        <Row className={"mb-3"} noGutters>
          <Input
            type={"password"}
            autoComplete="new-password"
            className={styles.passwordInput}
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            onKeyDown={onPressEnter}
            placeholder={"Enter new password"}
            autoFocus/>
          <i
            className={"d-flex align-items-center pl-2 ml-1 fa-thin fa-circle-question"}
            onMouseLeave={() => {
              setDisplayPasswordReqs(false)
            }}
            onMouseEnter={() => {
              setDisplayPasswordReqs(true)
            }}/>
          {displayPasswordReqs && <div className={styles.passwordReqsContainer}>
            <p>
              Please add a strong password by making sure
              it consists of at least:
              <br/>
              <br/>
              8 characters
              <br/>
              1 number
              <br/>
              1 special character
              <br/>
              <br/>
              e.g. jWa8katy9A=4
            </p>
          </div>}
        </Row>
        <Row noGutters>
          <Input
            type={"password"}
            autoComplete="off"
            onKeyDown={onPressEnter}
            className={styles.passwordInput}
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            placeholder={"Re-enter new password"}/>
        </Row>
        {error && <Row className={"mt-3"} noGutters>
          <ModalErrorAlert error={error} translator={translator}/>
        </Row>}
      </div>
    }/>
}
