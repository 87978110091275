/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateBrandMutationInput = {
    brand: string;
    name?: string | null | undefined;
    logo?: string | null | undefined;
    vat?: string | null | undefined;
    gln?: string | null | undefined;
    aliases?: Array<string | null> | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateBrandMutationVariables = {
    input: UpdateBrandMutationInput;
};
export type UpdateBrandMutationResponse = {
    readonly updateBrand: {
        readonly brand: {
            readonly id: string;
            readonly name: string;
            readonly logo: string | null;
        } | null;
    } | null;
};
export type UpdateBrandMutation = {
    readonly response: UpdateBrandMutationResponse;
    readonly variables: UpdateBrandMutationVariables;
};



/*
mutation UpdateBrandMutation(
  $input: UpdateBrandMutationInput!
) {
  updateBrand(input: $input) {
    brand {
      id
      name
      logo
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBrandMutationPayload",
    "kind": "LinkedField",
    "name": "updateBrand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandNode",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBrandMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBrandMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a1d8bfe8b226a4ae121d5eb2e5a9e93f",
    "id": null,
    "metadata": {},
    "name": "UpdateBrandMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBrandMutation(\n  $input: UpdateBrandMutationInput!\n) {\n  updateBrand(input: $input) {\n    brand {\n      id\n      name\n      logo\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f7393da9ad94787f62195fbf5381c9c5';
export default node;
