import {graphql} from "react-relay";
import {UpdateUserMutation} from "./__generated__/UpdateUserMutation.graphql";
import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";

export default createMutationBoilerplate<UpdateUserMutation>(
  graphql`
      mutation UpdateUserMutation($input: UpdateUserMutationInput!) {
          updateUser(input: $input) {
              user {
                  id
                  firstName
                  lastName
                  email
                  avatar
              }
          }
      }
  `
)