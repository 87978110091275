import React from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import styles from './NoCompanySelected.module.scss';

function CompanyChoice({name, disabled = false, id, onSelect}) {
  return <Col md={3}>
    <Card className={'shadow-sm ' + styles.companyOption + ' ' + (disabled ? styles.disabled : '')}
          onClick={e => {
            if (!disabled) {
              onSelect(id)
            }
          }}>
      <CardBody>
        {name}
      </CardBody>
    </Card>
  </Col>
}

export default function NoCompanySelected({selectText, options, onSelect}) {
  return <div>
    <Container className={'mt-5'}>
      <h2 className={'my-5'}>Please select a {selectText}</h2>
      <Row>
        {options.map(({id, name}) => {
          return <CompanyChoice key={id} name={name} id={id} onSelect={onSelect}/>
        })}
        <CompanyChoice disabled name={<span className={'text-muted'}>
          <i className="fas fa-plus mr-3"/> Create a new {selectText}
          </span>
        }/>
      </Row>
    </Container>
  </div>
}

