import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import './ui-kit/src/assets/fontawesome-pro/css/all.css';
import 'simple-line-icons/css/simple-line-icons.css';
import "react-toastify/dist/ReactToastify.css";
import './scss/style.scss'

import {DefaultLayout} from './containers';
import {Provider} from "react-redux";

import Register from "./views/Pages/Register/Register";
import Login from "./views/Pages/Login/Login";
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/Pages/ForgotPassword/ResetPassword";

import {EnvFetcher} from "./ui-kit/src/commons/http";
import store from "./store";

import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <EnvFetcher onLoad={(envConfig) => {
        if (!envConfig.sentry_dsn) {
          return;
        }
        Sentry.init({
          dsn: envConfig.sentry_dsn,
          environment: envConfig.environment_name,
          release: envConfig.release_name,
          integrations: [new BrowserTracing()]
        })
      }}>
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login}/>
              <Route exact path="/login/:newAccount" name="Login Page" component={Login}/>
              <Route exact path="/register" name="Register Page" component={Register}/>
              <Route exact path="/forgot-password" name="Forgot Password Page" component={ForgotPassword}/>
              <Route exact path="/password-reset" name="Forgot Password Page" component={ResetPassword}/>
              <Route path="/" name="Home" component={DefaultLayout}/>
            </Switch>
          </BrowserRouter>
        </Provider>
      </EnvFetcher>
    </QueryClientProvider>
  );
}

export default App;
