function getIntercomAppId() {
  return 'n5lxcf3m'
}


type Window = {
  Intercom?: (action: 'boot', initData: { app_id: string, name?: string, email?: string, user_id?: string }) => void;
}

export function initializeAnonymousInUser(): void {
  let w: Window = (window as unknown) as Window;
  if (!w.Intercom) {
    return;
  }
  w.Intercom('boot', {
    app_id: getIntercomAppId()
  })
}

export function initializeLoggedInUser(name: string, email: string, id: string): void {
  let w: Window = (window as unknown) as Window;
  if (!w.Intercom) {
    return;
  }
  w.Intercom('boot', {
    app_id: getIntercomAppId(),
    name,
    email,
    user_id: id
  })
}