import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";
import {DeleteBrandApiKeyMutation} from "./__generated__/DeleteBrandApiKeyMutation.graphql";

const mutation = graphql`
    mutation DeleteBrandApiKeyMutation($input: DeleteBrandIngestTokenMutationInput!) {
        deleteBrandIngestToken(input: $input) {
            clientMutationId
            success
        }
    }
`

export default createMutationBoilerplate<DeleteBrandApiKeyMutation>(mutation);
