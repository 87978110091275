import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";
import {CreateBrandMutation} from "./__generated__/CreateBrandMutation.graphql";


const mutation = graphql`
  mutation CreateBrandMutation($input: CreateBrandMutationInput!) {
    createBrand(input: $input) {
      brand {
        id
        name
      }
    }
  }
`;

export default createMutationBoilerplate<CreateBrandMutation>(mutation);
