import React from 'react';
import createBrandApiKey from "../mutations/CreateBrandApiKey";
import environment from "../Environment";
import {ourToast} from "../ui-kit/src/atoms/Toast";
import deleteBrandApiKey from "../mutations/DeleteBrandApiKey";
import {graphql, QueryRenderer} from "react-relay";
import {
  ManageApiKeysModalContentQuery,
  ManageApiKeysModalContentQueryResponse
} from "./__generated__/ManageApiKeysModalContentQuery.graphql";
import {ErrorAlert} from "../ui-kit/src/commons/errors";
import styles from "./ManageApiKeysModal.module.scss";
import Button from "../ui-kit/src/atoms/Button";
import Loading from "../ui-kit/src/atoms/Loading";

const query = graphql`
    query ManageApiKeysModalContentQuery ($id: ID!) {
        listBrandIngestTokens(id: $id, tags: ["public-api"]) {
            edges {
                node {
                    id
                    token
                    addTags
                }
            }
        }
    }
`;

type ApiKeyItemsProps = {
  apiKeys: ManageApiKeysModalContentQueryResponse['listBrandIngestTokens'],
  onDeleteKey: (token: string) => void
}

function ApiKeyItems({apiKeys, onDeleteKey}: ApiKeyItemsProps) {

  return <>
    {apiKeys?.edges.map((key, i) => {
      if (key?.node?.id && key?.node?.token) {
        return <>
          <div className={`d-flex align-items-center ${styles.separator}`} key={key.node.id}>
            <div>
              <div className={styles.itemSubtitle}>Key {i + 1}</div>
              <div className={styles.itemTitle}>{key.node.token}</div>
            </div>
            <i className={`fa-solid fa-circle-xmark ml-auto ${styles.deleteKeyButton}`}
               onClick={() => onDeleteKey(key.node?.token || '')}/>
          </div>
          <hr className={styles.separator}/>
        </>
      } else return null;
    }) || []}
  </>
}

export default function ManageApiKeysModalContent({brandId}: {brandId: string}) {

  const createKeyMutation = (retry: (() => void) | null) => {
    createBrandApiKey(
      environment,
      {
        id: brandId,
      },
      () => {
        ourToast('success', 'Key created successfully');
        retry && retry();
      },
      (err) => {
        ourToast('error', 'Failed to create a new API Key', err.message);
      }
    )
  }

  const deleteKeyMutation = (retry: (() => void) | null, token: string) => {
    deleteBrandApiKey(
      environment,
      {
        token: token
      },
      () => {
        ourToast('success', 'Key deleted successfully');
        retry && retry();
      },
      (err) => {
        ourToast('error', 'Failed to delete the selected API Key', err.message);
      }
    )
  }

  return <QueryRenderer<ManageApiKeysModalContentQuery>
    environment={environment}
    query={query}
    variables={{id: brandId}}
    render={({props, error, retry}) => {
      if (error) {
        return <ErrorAlert error={error} />
      }

      if(props?.listBrandIngestTokens) {
        return <div className={styles.modalContainer}>
          <Button onClick={() => createKeyMutation(retry)} className={'mb-4'}>
            <i className={'fa-regular fa-plus'}/>
            <p className={styles.buttonText}>Create API Key</p>
          </Button>
          <hr className={styles.separator}/>
          <ApiKeyItems apiKeys={props.listBrandIngestTokens} onDeleteKey={(token) => deleteKeyMutation(retry, token)}/>
        </div>
      }

      return <div className={'d-flex justify-content-center py-3'}>
        <Loading />
      </div>
    }}
  />
}
