import React from 'react'
import GrayModal from "../ui-kit/src/dataDisplay/GrayModal";
import styles from './ManageApiKeysModal.module.scss'
import ManageApiKeysModalContent from "./ManageApiKeysModalContent";

function CloseIcon({toggle}: {toggle: () => void}) {

  return <div className={styles.closeButtonContainer}>
    <i className={`fa-regular fa-xmark ${styles.closeButton}`} onClick={toggle}/>
  </div>
}

type ManageApiKeysModalProps = {
  isModalOpen: boolean,
  toggle: () => void,
  brandId: string
}

export default function ManageApiKeysModal({isModalOpen, toggle, brandId}: ManageApiKeysModalProps) {

  return <GrayModal isOpen={isModalOpen}
                    toggle={toggle}
                    title={<p className={styles.title}>Manage API keys</p>}
                    bodyContent={<ManageApiKeysModalContent brandId={brandId}/>}
                    style={{minWidth: "55.25rem"}}
                    customButtons={<CloseIcon toggle={toggle}/>}
  />
}
