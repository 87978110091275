import React, {useState} from "react";
import bluredNavbar from "../../../assets/svg/navigation.svg";
import {withRouter} from "react-router-dom";
import environment from "../../../Environment";
import ResetPasswordConfirmationModalContent
  from "../../../ui-kit/src/specialized/userForms/forgotPassword/ResetPasswordConfirmationModalContent";
import GrayModal from "../../../ui-kit/src/dataDisplay/GrayModal";
import {noop} from "../../../common/utilities";
import NewPasswordModal from "../../../ui-kit/src/specialized/userForms/forgotPassword/NewPasswordModal";

const MODALS = {
  NEW_PASSWORD: "password_reset",
  PASSWORD_CHANGED: "check_email"
}

export function ForgotPassword() {
  let [currentModal, setCurrentModal] = useState(MODALS.NEW_PASSWORD)

  return <div className="app flex-row align-items-center">
    <img src={bluredNavbar} className="blured-navbar" alt={"Blurred nav-bar"}/>
    {currentModal === MODALS.NEW_PASSWORD ?
      <NewPasswordModal environment={environment}
                        scope={"brands"}
                        onSend={() => setCurrentModal(MODALS.PASSWORD_CHANGED)}/> :
      <GrayModal isOpen={true}
                 toggle={noop}
                 bodyContent={<ResetPasswordConfirmationModalContent
                   isTriggerConfirmation={false}/>}
                 style={{minWidth: "34rem"}}/>
    }
  </div>
}

export default withRouter(ForgotPassword)