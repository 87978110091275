import React from "react";
import {Col, FormGroup, Row} from "reactstrap";
import styles from "../SettingsModal.module.scss"

function ChangePasswordForm(props) {
  return <div>
    <Row>
      <p className={styles.subTitle + " mb-4 font-weight-bold"}>Change Password</p>
    </Row>
    <Row>
      <FormGroup style={{marginLeft: "10px"}}>
        <Row>
          <Col>
            <input
              type="password" className={'form-control'}
              id={"password-input"} autoComplete={"new-password"}
              value={props.passwordValue}
              onChange={e => {
                props.onPasswordUpdate(e.target.value)
              }}
              placeholder={"Enter New Password"}
            />
          </Col>
        </Row>
      </FormGroup>
    </Row>
    <Row>
      <FormGroup style={{marginLeft: "10px"}}>
        <Row>
          <Col>
            <input
              type="password" className={'form-control'}
              id={"confirm-password-input"}
              value={props.confirmPasswordValue}
              onChange={e => {
                props.onConfirmPasswordUpdate(e.target.value)
              }}
              placeholder={"Re-enter New Password"}
            />
          </Col>
        </Row>
      </FormGroup>
    </Row>
    <Row className={"pt-3"}>
      {props.errors.length > 0 &&
      <p id={"error-text"} className={styles.errorText}>{"Error: " + props.errors[0].passwordErrors}</p>}
    </Row>
  </div>
}

export default ChangePasswordForm;
