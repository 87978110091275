import React from "react";
import {withUser} from "../../../../context/UserContext";
import {Col, FormGroup, Label, Row} from "reactstrap";
import styles from "../SettingsModal.module.scss"


function UserInfoForm(props) {
  return <div>
    <Row>
      <p className={styles.subTitle + " mb-4 font-weight-bold"}>User Information</p>
    </Row>
    <Row>
      <FormGroup>
        <Row>
          <Col md={3}>
            <Label className={styles.userFormLabel}>First name</Label>
          </Col>
          <Col md={8} className={'pl-4'}>
            <input
              id={"first-name-input"}
              type="text" className={'form-control'}
              value={props.firstNameValue}
              onChange={e => {
                props.onFirstNameUpdate(e.target.value)
              }}
            />
          </Col>
        </Row>
      </FormGroup>
    </Row>
    <Row>
      <FormGroup>
        <Row>
          <Col md={3}>
            <Label className={styles.userFormLabel}>Last name</Label>
          </Col>
          <Col md={8} className={'pl-4'}>
            <input
              type="text" className={'form-control'}
              value={props.lastNameValue}
              onChange={e => {
                props.onLastNameUpdate(e.target.value)
              }}
            />
          </Col>
        </Row>
      </FormGroup>
    </Row>
    <Row>
      <FormGroup>
        <Row>
          <Col md={3}>
            <Label className={styles.userFormLabel}>Email</Label>
          </Col>
          <Col md={8} className={'pl-4'}>
            <input
              id={"email-input"}
              type="text" className={'form-control'}
              value={props.emailValue}
              onChange={e => {
                props.onEmailUpdate(e.target.value)
              }}
            />
          </Col>
        </Row>
      </FormGroup>
    </Row>
    {props.brandName && <Row>
      <FormGroup>
        <Row>
          <Col md={3}>
            <Label className={styles.userFormLabel}>Brand</Label>
          </Col>
          <Col md={8} className={'pl-4'}>
            <input disabled={true} className={"form-control"}
                   value={props.brandName}/>
          </Col>
        </Row>
      </FormGroup>
    </Row>}
    <Row className={"pt-3"}>
      {props.errors.length > 0 &&
      <p id={"error-text"} className={styles.errorText}>{"Error: " + props.errors[0].userErrors}</p>}
    </Row>
  </div>
}


export default withUser(UserInfoForm);
