import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";
import {ChangePasswordMutation} from "./__generated__/ChangePasswordMutation.graphql";


const mutation = graphql`
  mutation ChangePasswordMutation($input: ChangePasswordMutationInput!) {
    changePassword(input: $input) {
      user {
        id
        firstName
        lastName
        email
        avatar
      }
    }
  }
`;

export default createMutationBoilerplate<ChangePasswordMutation>(mutation);
