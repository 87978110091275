import React, {useEffect, useState} from 'react';
import {withRouter, useHistory} from "react-router-dom";
import bluredNavbar from "../../../assets/svg/navigation.svg";
import CreatingAccountModal from "../../../ui-kit/src/specialized/userForms/creatingAccount/CreatingAccountModal";
import environment, {setAuthToken} from "../../../Environment"
import LoginMutation from "../../../mutations/LoginMutation";
import CreateCompanyUserMutation from "../../../mutations/CreateCompanyUserMutation";
import {initializeAnonymousInUser} from "../../../common/intercomIntegration";
import {UserRegistrationDataType} from "../../../ui-kit/src/specialized/userForms/creatingAccount/types";
import {PayloadError} from "relay-runtime/lib/network/RelayNetworkTypes";
import {noop} from "../../../common/utilities";

function Register() {
  const [errors, setErrors] = useState<readonly PayloadError[] | Error>([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    initializeAnonymousInUser();
  }, [])

  const onSubmit = (data: UserRegistrationDataType, onSuccessCallback: () => void) => {
    setIsLoading(true)
    CreateCompanyUserMutation(
      {
        companyName: data.companyName,
        vatCvr: data.vatCvr,
        address: data.address,
        postNumber: data.postNumber,
        city: data.city,
        firstName: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        password: data.password,
        repeatPassword: data.repeatPassword,
        checked: data.checked,
        companyType: data.companyType,
      },
        environment,
      (email, password) => {
        onSuccessCallback();
        onSuccess(email, password);
      },
        onError
    )
  }

  const onSuccess = (email: string, password: string) => {
    setIsLoading(false)
    LoginMutation(
        email,
        password,
        (response) => {
          setAuthToken(response.tokenAuth.token);
          history.push("/")
        },
        (errors) => {
          onError(errors)
        }
    )
  }

  const onError = (errors: readonly PayloadError[] | Error) => {
    setErrors(errors)
    setIsLoading(false)
  }

  return <div className="app flex-row align-items-center">
    <img src={bluredNavbar} alt={"Blurred nav-bar"} className={"blured-navbar"}/>
    <CreatingAccountModal isOpen={true}
                          loading={isLoading}
                          errors={errors}
                          scope={'brands'}
                          onCancel={() => {
                            window.location.href = "https://vuuh.com";
                          }}
                          onSubmit={onSubmit}
                          toggle={noop}
    />
  </div>
}

export default withRouter(Register);
