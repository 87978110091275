import React from "react";
import styles from './LoadingModalContent.module.scss';

type LoadingModalContentProps = {
  title?: string | JSX.Element,
  subtitle?: string | JSX.Element,
  actionButton?: JSX.Element | JSX.Element[]
}

export default function LoadingModalContent({title = "Please Wait...", subtitle, actionButton}: LoadingModalContentProps) {
  return <div className={styles.container}>
    <i className={`fa-solid fa-spinner fa-spin ${styles.spinner}`}/>
    {typeof title === "string" ? <h5 className={`mt-3 mb-0 ${styles.title}`}>{title}</h5> : title}
    {typeof subtitle === "string" ? <p className={`mt-1 mb-0 ${styles.infoText}`}>{subtitle}</p> : subtitle}
    {!!actionButton && actionButton}
  </div>;
}
