export type CountryDataType = {
  countryName: string,
  iso2: string,
  iso3: string,
  numericPhonePrefix: number,
  phone: string,
};

export const getCountryData = (value: string | number, key: string) => {
  return countriesData.find(country => country[key as keyof CountryDataType] === value) || null;
}

export const countriesData: CountryDataType[] = [
  {
    countryName: "Afghanistan",
    iso2: "AF",
    iso3: "AFG",
    numericPhonePrefix: 93,
    phone: "93",
  },
  {
    countryName: "Albania",
    iso2: "AL",
    iso3: "ALB",
    numericPhonePrefix: 355,
    phone: "355",
  },
  {
    countryName: "Algeria",
    iso2: "DZ",
    iso3: "DZA",
    numericPhonePrefix: 213,
    phone: "213",
  },
  {
    countryName: "American Samoa",
    iso2: "AS",
    iso3: "ASM",
    numericPhonePrefix: 1,
    phone: "1-684",
  },
  {
    countryName: "Andorra",
    iso2: "AD",
    iso3: "AND",
    numericPhonePrefix: 376,
    phone: "376",
  },
  {
    countryName: "Angola",
    iso2: "AO",
    iso3: "AGO",
    numericPhonePrefix: 244,
    phone: "244",
  },
  {
    countryName: "Anguilla",
    iso2: "AI",
    iso3: "AIA",
    numericPhonePrefix: 1,
    phone: "1-264",
  },
  {
    countryName: "Antarctica",
    iso2: "AQ",
    iso3: "ATA",
    numericPhonePrefix: 672,
    phone: "672",
  },
  {
    countryName: "Antigua and Barbuda",
    iso2: "AG",
    iso3: "ATG",
    numericPhonePrefix: 1,
    phone: "1-268",
  },
  {
    countryName: "Argentina",
    iso2: "AR",
    iso3: "ARG",
    numericPhonePrefix: 54,
    phone: "54",
  },
  {
    countryName: "Armenia",
    iso2: "AM",
    iso3: "ARM",
    numericPhonePrefix: 374,
    phone: "374",
  },
  {
    countryName: "Aruba",
    iso2: "AW",
    iso3: "ABW",
    numericPhonePrefix: 297,
    phone: "297",
  },
  {
    countryName: "Australia",
    iso2: "AU",
    iso3: "AUS",
    numericPhonePrefix: 61,
    phone: "61",
  },
  {
    countryName: "Austria",
    iso2: "AT",
    iso3: "AUT",
    numericPhonePrefix: 43,
    phone: "43",
  },
  {
    countryName: "Azerbaijan",
    iso2: "AZ",
    iso3: "AZE",
    numericPhonePrefix: 994,
    phone: "994",
  },
  {
    countryName: "Bahamas",
    iso2: "BS",
    iso3: "BHS",
    numericPhonePrefix: 1,
    phone: "1-242",
  },
  {
    countryName: "Bahrain",
    iso2: "BH",
    iso3: "BHR",
    numericPhonePrefix: 973,
    phone: "973",
  },
  {
    countryName: "Bangladesh",
    iso2: "BD",
    iso3: "BGD",
    numericPhonePrefix: 880,
    phone: "880",
  },
  {
    countryName: "Barbados",
    iso2: "BB",
    iso3: "BRB",
    numericPhonePrefix: 1,
    phone: "1-246",
  },
  {
    countryName: "Belarus",
    iso2: "BY",
    iso3: "BLR",
    numericPhonePrefix: 375,
    phone: "375",
  },
  {
    countryName: "Belgium",
    iso2: "BE",
    iso3: "BEL",
    numericPhonePrefix: 32,
    phone: "32",
  },
  {
    countryName: "Belize",
    iso2: "BZ",
    iso3: "BLZ",
    numericPhonePrefix: 501,
    phone: "501",
  },
  {
    countryName: "Benin",
    iso2: "BJ",
    iso3: "BEN",
    numericPhonePrefix: 229,
    phone: "229",
  },
  {
    countryName: "Bermuda",
    iso2: "BM",
    iso3: "BMU",
    numericPhonePrefix: 1,
    phone: "1-441",
  },
  {
    countryName: "Bhutan",
    iso2: "BT",
    iso3: "BTN",
    numericPhonePrefix: 975,
    phone: "975",
  },
  {
    countryName: "Bolivia",
    iso2: "BO",
    iso3: "BOL",
    numericPhonePrefix: 591,
    phone: "591",
  },
  {
    countryName: "Bosnia and Herzegovina",
    iso2: "BA",
    iso3: "BIH",
    numericPhonePrefix: 387,
    phone: "387",
  },
  {
    countryName: "Botswana",
    iso2: "BW",
    iso3: "BWA",
    numericPhonePrefix: 267,
    phone: "267",
  },
  {
    countryName: "Brazil",
    iso2: "BR",
    iso3: "BRA",
    numericPhonePrefix: 55,
    phone: "55",
  },
  {
    countryName: "British Indian Ocean Territory",
    iso2: "IO",
    iso3: "IOT",
    numericPhonePrefix: 246,
    phone: "246",
  },
  {
    countryName: "British Virgin Islands",
    iso2: "VG",
    iso3: "VGB",
    numericPhonePrefix: 1,
    phone: "1-284",
  },
  {
    countryName: "Brunei",
    iso2: "BN",
    iso3: "BRN",
    numericPhonePrefix: 673,
    phone: "673",
  },
  {
    countryName: "Bulgaria",
    iso2: "BG",
    iso3: "BGR",
    numericPhonePrefix: 359,
    phone: "359",
  },
  {
    countryName: "Burkina Faso",
    iso2: "BF",
    iso3: "BFA",
    numericPhonePrefix: 226,
    phone: "226",
  },
  {
    countryName: "Burundi",
    iso2: "BI",
    iso3: "BDI",
    numericPhonePrefix: 257,
    phone: "257",
  },
  {
    countryName: "Cambodia",
    iso2: "KH",
    iso3: "KHM",
    numericPhonePrefix: 855,
    phone: "855",
  },
  {
    countryName: "Cameroon",
    iso2: "CM",
    iso3: "CMR",
    numericPhonePrefix: 237,
    phone: "237",
  },
  {
    countryName: "Canada",
    iso2: "CA",
    iso3: "CAN",
    numericPhonePrefix: 1,
    phone: "1",
  },
  {
    countryName: "Cape Verde",
    iso2: "CV",
    iso3: "CPV",
    numericPhonePrefix: 238,
    phone: "238",
  },
  {
    countryName: "Cayman Islands",
    iso2: "KY",
    iso3: "CYM",
    numericPhonePrefix: 1,
    phone: "1-345",
  },
  {
    countryName: "Central African Republic",
    iso2: "CF",
    iso3: "CAF",
    numericPhonePrefix: 236,
    phone: "236",
  },
  {
    countryName: "Chad",
    iso2: "TD",
    iso3: "TCD",
    numericPhonePrefix: 235,
    phone: "235",
  },
  {
    countryName: "Chile",
    iso2: "CL",
    iso3: "CHL",
    numericPhonePrefix: 56,
    phone: "56",
  },
  {
    countryName: "China",
    iso2: "CN",
    iso3: "CHN",
    numericPhonePrefix: 86,
    phone: "86",
  },
  {
    countryName: "Christmas Island",
    iso2: "CX",
    iso3: "CXR",
    numericPhonePrefix: 61,
    phone: "61",
  },
  {
    countryName: "Cocos Islands",
    iso2: "CC",
    iso3: "CCK",
    numericPhonePrefix: 61,
    phone: "61",
  },
  {
    countryName: "Colombia",
    iso2: "CO",
    iso3: "COL",
    numericPhonePrefix: 57,
    phone: "57",
  },
  {
    countryName: "Comoros",
    iso2: "KM",
    iso3: "COM",
    numericPhonePrefix: 269,
    phone: "269",
  },
  {
    countryName: "Cook Islands",
    iso2: "CK",
    iso3: "COK",
    numericPhonePrefix: 682,
    phone: "682",
  },
  {
    countryName: "Costa Rica",
    iso2: "CR",
    iso3: "CRI",
    numericPhonePrefix: 506,
    phone: "506",
  },
  {
    countryName: "Croatia",
    iso2: "HR",
    iso3: "HRV",
    numericPhonePrefix: 385,
    phone: "385",
  },
  {
    countryName: "Cuba",
    iso2: "CU",
    iso3: "CUB",
    numericPhonePrefix: 53,
    phone: "53",
  },
  {
    countryName: "Curacao",
    iso2: "CW",
    iso3: "CUW",
    numericPhonePrefix: 599,
    phone: "599",
  },
  {
    countryName: "Cyprus",
    iso2: "CY",
    iso3: "CYP",
    numericPhonePrefix: 357,
    phone: "357",
  },
  {
    countryName: "Czech Republic",
    iso2: "CZ",
    iso3: "CZE",
    numericPhonePrefix: 420,
    phone: "420",
  },
  {
    countryName: "Democratic Republic of the Congo",
    iso2: "CD",
    iso3: "COD",
    numericPhonePrefix: 243,
    phone: "243",
  },
  {
    countryName: "Denmark",
    iso2: "DK",
    iso3: "DNK",
    numericPhonePrefix: 45,
    phone: "45",
  },
  {
    countryName: "Djibouti",
    iso2: "DJ",
    iso3: "DJI",
    numericPhonePrefix: 253,
    phone: "253",
  },
  {
    countryName: "Dominica",
    iso2: "DM",
    iso3: "DMA",
    numericPhonePrefix: 1,
    phone: "1-767",
  },
  {
    countryName: "Dominican Republic",
    iso2: "DO",
    iso3: "DOM",
    numericPhonePrefix: 1,
    phone: "1-809, 1-829, 1-849",
  },
  {
    countryName: "East Timor",
    iso2: "TL",
    iso3: "TLS",
    numericPhonePrefix: 670,
    phone: "670",
  },
  {
    countryName: "Ecuador",
    iso2: "EC",
    iso3: "ECU",
    numericPhonePrefix: 593,
    phone: "593",
  },
  {
    countryName: "Egypt",
    iso2: "EG",
    iso3: "EGY",
    numericPhonePrefix: 20,
    phone: "20",
  },
  {
    countryName: "El Salvador",
    iso2: "SV",
    iso3: "SLV",
    numericPhonePrefix: 503,
    phone: "503",
  },
  {
    countryName: "Equatorial Guinea",
    iso2: "GQ",
    iso3: "GNQ",
    numericPhonePrefix: 240,
    phone: "240",
  },
  {
    countryName: "Eritrea",
    iso2: "ER",
    iso3: "ERI",
    numericPhonePrefix: 291,
    phone: "291",
  },
  {
    countryName: "Estonia",
    iso2: "EE",
    iso3: "EST",
    numericPhonePrefix: 372,
    phone: "372",
  },
  {
    countryName: "Ethiopia",
    iso2: "ET",
    iso3: "ETH",
    numericPhonePrefix: 251,
    phone: "251",
  },
  {
    countryName: "Falkland Islands",
    iso2: "FK",
    iso3: "FLK",
    numericPhonePrefix: 500,
    phone: "500",
  },
  {
    countryName: "Faroe Islands",
    iso2: "FO",
    iso3: "FRO",
    numericPhonePrefix: 298,
    phone: "298",
  },
  {
    countryName: "Fiji",
    iso2: "FJ",
    iso3: "FJI",
    numericPhonePrefix: 679,
    phone: "679",
  },
  {
    countryName: "Finland",
    iso2: "FI",
    iso3: "FIN",
    numericPhonePrefix: 358,
    phone: "358",
  },
  {
    countryName: "France",
    iso2: "FR",
    iso3: "FRA",
    numericPhonePrefix: 33,
    phone: "33",
  },
  {
    countryName: "French Polynesia",
    iso2: "PF",
    iso3: "PYF",
    numericPhonePrefix: 689,
    phone: "689",
  },
  {
    countryName: "Gabon",
    iso2: "GA",
    iso3: "GAB",
    numericPhonePrefix: 241,
    phone: "241",
  },
  {
    countryName: "Gambia",
    iso2: "GM",
    iso3: "GMB",
    numericPhonePrefix: 220,
    phone: "220",
  },
  {
    countryName: "Georgia",
    iso2: "GE",
    iso3: "GEO",
    numericPhonePrefix: 995,
    phone: "995",
  },
  {
    countryName: "Germany",
    iso2: "DE",
    iso3: "DEU",
    numericPhonePrefix: 49,
    phone: "49",
  },
  {
    countryName: "Ghana",
    iso2: "GH",
    iso3: "GHA",
    numericPhonePrefix: 233,
    phone: "233",
  },
  {
    countryName: "Gibraltar",
    iso2: "GI",
    iso3: "GIB",
    numericPhonePrefix: 350,
    phone: "350",
  },
  {
    countryName: "Greece",
    iso2: "EL",
    iso3: "GRC",
    numericPhonePrefix: 30,
    phone: "30",
  },
  {
    countryName: "Greenland",
    iso2: "GL",
    iso3: "GRL",
    numericPhonePrefix: 299,
    phone: "299",
  },
  {
    countryName: "Grenada",
    iso2: "GD",
    iso3: "GRD",
    numericPhonePrefix: 1,
    phone: "1-473",
  },
  {
    countryName: "Guam",
    iso2: "GU",
    iso3: "GUM",
    numericPhonePrefix: 1,
    phone: "1-671",
  },
  {
    countryName: "Guatemala",
    iso2: "GT",
    iso3: "GTM",
    numericPhonePrefix: 502,
    phone: "502",
  },
  {
    countryName: "Guernsey",
    iso2: "GG",
    iso3: "GGY",
    numericPhonePrefix: 44,
    phone: "44-1481",
  },
  {
    countryName: "Guinea",
    iso2: "GN",
    iso3: "GIN",
    numericPhonePrefix: 224,
    phone: "224",
  },
  {
    countryName: "Guinea-Bissau",
    iso2: "GW",
    iso3: "GNB",
    numericPhonePrefix: 245,
    phone: "245",
  },
  {
    countryName: "Guyana",
    iso2: "GY",
    iso3: "GUY",
    numericPhonePrefix: 592,
    phone: "592",
  },
  {
    countryName: "Haiti",
    iso2: "HT",
    iso3: "HTI",
    numericPhonePrefix: 509,
    phone: "509",
  },
  {
    countryName: "Honduras",
    iso2: "HN",
    iso3: "HND",
    numericPhonePrefix: 504,
    phone: "504",
  },
  {
    countryName: "Hong Kong",
    iso2: "HK",
    iso3: "HKG",
    numericPhonePrefix: 852,
    phone: "852",
  },
  {
    countryName: "Hungary",
    iso2: "HU",
    iso3: "HUN",
    numericPhonePrefix: 36,
    phone: "36",
  },
  {
    countryName: "Iceland",
    iso2: "IS",
    iso3: "ISL",
    numericPhonePrefix: 354,
    phone: "354",
  },
  {
    countryName: "India",
    iso2: "IN",
    iso3: "IND",
    numericPhonePrefix: 91,
    phone: "91",
  },
  {
    countryName: "Indonesia",
    iso2: "ID",
    iso3: "IDN",
    numericPhonePrefix: 62,
    phone: "62",
  },
  {
    countryName: "Iran",
    iso2: "IR",
    iso3: "IRN",
    numericPhonePrefix: 98,
    phone: "98",
  },
  {
    countryName: "Iraq",
    iso2: "IQ",
    iso3: "IRQ",
    numericPhonePrefix: 964,
    phone: "964",
  },
  {
    countryName: "Ireland",
    iso2: "IE",
    iso3: "IRL",
    numericPhonePrefix: 353,
    phone: "353",
  },
  {
    countryName: "Isle of Man",
    iso2: "IM",
    iso3: "IMN",
    numericPhonePrefix: 44,
    phone: "44-1624",
  },
  {
    countryName: "Israel",
    iso2: "IL",
    iso3: "ISR",
    numericPhonePrefix: 972,
    phone: "972",
  },
  {
    countryName: "Italy",
    iso2: "IT",
    iso3: "ITA",
    numericPhonePrefix: 39,
    phone: "39",
  },
  {
    countryName: "Ivory Coast",
    iso2: "CI",
    iso3: "CIV",
    numericPhonePrefix: 225,
    phone: "225",
  },
  {
    countryName: "Jamaica",
    iso2: "JM",
    iso3: "JAM",
    numericPhonePrefix: 1,
    phone: "1-876",
  },
  {
    countryName: "Japan",
    iso2: "JP",
    iso3: "JPN",
    numericPhonePrefix: 81,
    phone: "81",
  },
  {
    countryName: "Jersey",
    iso2: "JE",
    iso3: "JEY",
    numericPhonePrefix: 44,
    phone: "44-1534",
  },
  {
    countryName: "Jordan",
    iso2: "JO",
    iso3: "JOR",
    numericPhonePrefix: 962,
    phone: "962",
  },
  {
    countryName: "Kazakhstan",
    iso2: "KZ",
    iso3: "KAZ",
    numericPhonePrefix: 7,
    phone: "7",
  },
  {
    countryName: "Kenya",
    iso2: "KE",
    iso3: "KEN",
    numericPhonePrefix: 254,
    phone: "254",
  },
  {
    countryName: "Kiribati",
    iso2: "KI",
    iso3: "KIR",
    numericPhonePrefix: 686,
    phone: "686",
  },
  {
    countryName: "Kosovo",
    iso2: "XK",
    iso3: "XKX",
    numericPhonePrefix: 383,
    phone: "383",
  },
  {
    countryName: "Kuwait",
    iso2: "KW",
    iso3: "KWT",
    numericPhonePrefix: 965,
    phone: "965",
  },
  {
    countryName: "Kyrgyzstan",
    iso2: "KG",
    iso3: "KGZ",
    numericPhonePrefix: 996,
    phone: "996",
  },
  {
    countryName: "Laos",
    iso2: "LA",
    iso3: "LAO",
    numericPhonePrefix: 856,
    phone: "856",
  },
  {
    countryName: "Latvia",
    iso2: "LV",
    iso3: "LVA",
    numericPhonePrefix: 371,
    phone: "371",
  },
  {
    countryName: "Lebanon",
    iso2: "LB",
    iso3: "LBN",
    numericPhonePrefix: 961,
    phone: "961",
  },
  {
    countryName: "Lesotho",
    iso2: "LS",
    iso3: "LSO",
    numericPhonePrefix: 266,
    phone: "266",
  },
  {
    countryName: "Liberia",
    iso2: "LR",
    iso3: "LBR",
    numericPhonePrefix: 231,
    phone: "231",
  },
  {
    countryName: "Libya",
    iso2: "LY",
    iso3: "LBY",
    numericPhonePrefix: 218,
    phone: "218",
  },
  {
    countryName: "Liechtenstein",
    iso2: "LI",
    iso3: "LIE",
    numericPhonePrefix: 423,
    phone: "423",
  },
  {
    countryName: "Lithuania",
    iso2: "LT",
    iso3: "LTU",
    numericPhonePrefix: 370,
    phone: "370",
  },
  {
    countryName: "Luxembourg",
    iso2: "LU",
    iso3: "LUX",
    numericPhonePrefix: 352,
    phone: "352",
  },
  {
    countryName: "Macau",
    iso2: "MO",
    iso3: "MAC",
    numericPhonePrefix: 853,
    phone: "853",
  },
  {
    countryName: "Macedonia",
    iso2: "MK",
    iso3: "MKD",
    numericPhonePrefix: 389,
    phone: "389",
  },
  {
    countryName: "Madagascar",
    iso2: "MG",
    iso3: "MDG",
    numericPhonePrefix: 261,
    phone: "261",
  },
  {
    countryName: "Malawi",
    iso2: "MW",
    iso3: "MWI",
    numericPhonePrefix: 265,
    phone: "265",
  },
  {
    countryName: "Malaysia",
    iso2: "MY",
    iso3: "MYS",
    numericPhonePrefix: 60,
    phone: "60",
  },
  {
    countryName: "Maldives",
    iso2: "MV",
    iso3: "MDV",
    numericPhonePrefix: 960,
    phone: "960",
  },
  {
    countryName: "Mali",
    iso2: "ML",
    iso3: "MLI",
    numericPhonePrefix: 223,
    phone: "223",
  },
  {
    countryName: "Malta",
    iso2: "MT",
    iso3: "MLT",
    numericPhonePrefix: 356,
    phone: "356",
  },
  {
    countryName: "Marshall Islands",
    iso2: "MH",
    iso3: "MHL",
    numericPhonePrefix: 692,
    phone: "692",
  },
  {
    countryName: "Mauritania",
    iso2: "MR",
    iso3: "MRT",
    numericPhonePrefix: 222,
    phone: "222",
  },
  {
    countryName: "Mauritius",
    iso2: "MU",
    iso3: "MUS",
    numericPhonePrefix: 230,
    phone: "230",
  },
  {
    countryName: "Mayotte",
    iso2: "YT",
    iso3: "MYT",
    numericPhonePrefix: 262,
    phone: "262",
  },
  {
    countryName: "Mexico",
    iso2: "MX",
    iso3: "MEX",
    numericPhonePrefix: 52,
    phone: "52",
  },
  {
    countryName: "Micronesia",
    iso2: "FM",
    iso3: "FSM",
    numericPhonePrefix: 691,
    phone: "691",
  },
  {
    countryName: "Moldova",
    iso2: "MD",
    iso3: "MDA",
    numericPhonePrefix: 373,
    phone: "373",
  },
  {
    countryName: "Monaco",
    iso2: "MC",
    iso3: "MCO",
    numericPhonePrefix: 377,
    phone: "377",
  },
  {
    countryName: "Mongolia",
    iso2: "MN",
    iso3: "MNG",
    numericPhonePrefix: 976,
    phone: "976",
  },
  {
    countryName: "Montenegro",
    iso2: "ME",
    iso3: "MNE",
    numericPhonePrefix: 382,
    phone: "382",
  },
  {
    countryName: "Montserrat",
    iso2: "MS",
    iso3: "MSR",
    numericPhonePrefix: 1,
    phone: "1-664",
  },
  {
    countryName: "Morocco",
    iso2: "MA",
    iso3: "MAR",
    numericPhonePrefix: 212,
    phone: "212",
  },
  {
    countryName: "Mozambique",
    iso2: "MZ",
    iso3: "MOZ",
    numericPhonePrefix: 258,
    phone: "258",
  },
  {
    countryName: "Myanmar",
    iso2: "MM",
    iso3: "MMR",
    numericPhonePrefix: 95,
    phone: "95",
  },
  {
    countryName: "Namibia",
    iso2: "NA",
    iso3: "NAM",
    numericPhonePrefix: 264,
    phone: "264",
  },
  {
    countryName: "Nauru",
    iso2: "NR",
    iso3: "NRU",
    numericPhonePrefix: 674,
    phone: "674",
  },
  {
    countryName: "Nepal",
    iso2: "NP",
    iso3: "NPL",
    numericPhonePrefix: 977,
    phone: "977",
  },
  {
    countryName: "Netherlands",
    iso2: "NL",
    iso3: "NLD",
    numericPhonePrefix: 31,
    phone: "31",
  },
  {
    countryName: "Netherlands Antilles",
    iso2: "AN",
    iso3: "ANT",
    numericPhonePrefix: 599,
    phone: "599",
  },
  {
    countryName: "New Caledonia",
    iso2: "NC",
    iso3: "NCL",
    numericPhonePrefix: 687,
    phone: "687",
  },
  {
    countryName: "New Zealand",
    iso2: "NZ",
    iso3: "NZL",
    numericPhonePrefix: 64,
    phone: "64",
  },
  {
    countryName: "Nicaragua",
    iso2: "NI",
    iso3: "NIC",
    numericPhonePrefix: 505,
    phone: "505",
  },
  {
    countryName: "Niger",
    iso2: "NE",
    iso3: "NER",
    numericPhonePrefix: 227,
    phone: "227",
  },
  {
    countryName: "Nigeria",
    iso2: "NG",
    iso3: "NGA",
    numericPhonePrefix: 234,
    phone: "234",
  },
  {
    countryName: "Niue",
    iso2: "NU",
    iso3: "NIU",
    numericPhonePrefix: 683,
    phone: "683",
  },
  {
    countryName: "North Korea",
    iso2: "KP",
    iso3: "PRK",
    numericPhonePrefix: 850,
    phone: "850",
  },
  {
    countryName: "Northern Mariana Islands",
    iso2: "MP",
    iso3: "MNP",
    numericPhonePrefix: 1,
    phone: "1-670",
  },
  {
    countryName: "Norway",
    iso2: "NO",
    iso3: "NOR",
    numericPhonePrefix: 47,
    phone: "47",
  },
  {
    countryName: "Oman",
    iso2: "OM",
    iso3: "OMN",
    numericPhonePrefix: 968,
    phone: "968",
  },
  {
    countryName: "Pakistan",
    iso2: "PK",
    iso3: "PAK",
    numericPhonePrefix: 92,
    phone: "92",
  },
  {
    countryName: "Palau",
    iso2: "PW",
    iso3: "PLW",
    numericPhonePrefix: 680,
    phone: "680",
  },
  {
    countryName: "Palestine",
    iso2: "PS",
    iso3: "PSE",
    numericPhonePrefix: 970,
    phone: "970",
  },
  {
    countryName: "Panama",
    iso2: "PA",
    iso3: "PAN",
    numericPhonePrefix: 507,
    phone: "507",
  },
  {
    countryName: "Papua New Guinea",
    iso2: "PG",
    iso3: "PNG",
    numericPhonePrefix: 675,
    phone: "675",
  },
  {
    countryName: "Paraguay",
    iso2: "PY",
    iso3: "PRY",
    numericPhonePrefix: 595,
    phone: "595",
  },
  {
    countryName: "Peru",
    iso2: "PE",
    iso3: "PER",
    numericPhonePrefix: 51,
    phone: "51",
  },
  {
    countryName: "Philippines",
    iso2: "PH",
    iso3: "PHL",
    numericPhonePrefix: 63,
    phone: "63",
  },
  {
    countryName: "Pitcairn",
    iso2: "PN",
    iso3: "PCN",
    numericPhonePrefix: 64,
    phone: "64",
  },
  {
    countryName: "Poland",
    iso2: "PL",
    iso3: "POL",
    numericPhonePrefix: 48,
    phone: "48",
  },
  {
    countryName: "Portugal",
    iso2: "PT",
    iso3: "PRT",
    numericPhonePrefix: 351,
    phone: "351",
  },
  {
    countryName: "Puerto Rico",
    iso2: "PR",
    iso3: "PRI",
    numericPhonePrefix: 1,
    phone: "1-787, 1-939",
  },
  {
    countryName: "Qatar",
    iso2: "QA",
    iso3: "QAT",
    numericPhonePrefix: 974,
    phone: "974",
  },
  {
    countryName: "Republic of the Congo",
    iso2: "CG",
    iso3: "COG",
    numericPhonePrefix: 242,
    phone: "242",
  },
  {
    countryName: "Reunion",
    iso2: "RE",
    iso3: "REU",
    numericPhonePrefix: 262,
    phone: "262",
  },
  {
    countryName: "Romania",
    iso2: "RO",
    iso3: "ROU",
    numericPhonePrefix: 40,
    phone: "40",
  },
  {
    countryName: "Russia",
    iso2: "RU",
    iso3: "RUS",
    numericPhonePrefix: 7,
    phone: "7",
  },
  {
    countryName: "Rwanda",
    iso2: "RW",
    iso3: "RWA",
    numericPhonePrefix: 250,
    phone: "250",
  },
  {
    countryName: "Saint Barthelemy",
    iso2: "BL",
    iso3: "BLM",
    numericPhonePrefix: 590,
    phone: "590",
  },
  {
    countryName: "Saint Helena",
    iso2: "SH",
    iso3: "SHN",
    numericPhonePrefix: 290,
    phone: "290",
  },
  {
    countryName: "Saint Kitts and Nevis",
    iso2: "KN",
    iso3: "KNA",
    numericPhonePrefix: 1,
    phone: "1-869",
  },
  {
    countryName: "Saint Lucia",
    iso2: "LC",
    iso3: "LCA",
    numericPhonePrefix: 1,
    phone: "1-758",
  },
  {
    countryName: "Saint Martin",
    iso2: "MF",
    iso3: "MAF",
    numericPhonePrefix: 1,
    phone: "590",
  },
  {
    countryName: "Saint Pierre and Miquelon",
    iso2: "PM",
    iso3: "SPM",
    numericPhonePrefix: 508,
    phone: "508",
  },
  {
    countryName: "Saint Vincent and the Grenadines",
    iso2: "VC",
    iso3: "VCT",
    numericPhonePrefix: 1,
    phone: "1-784",
  },
  {
    countryName: "Samoa",
    iso2: "WS",
    iso3: "WSM",
    numericPhonePrefix: 685,
    phone: "685",
  },
  {
    countryName: "San Marino",
    iso2: "SM",
    iso3: "SMR",
    numericPhonePrefix: 378,
    phone: "378",
  },
  {
    countryName: "Sao Tome and Principe",
    iso2: "ST",
    iso3: "STP",
    numericPhonePrefix: 239,
    phone: "239",
  },
  {
    countryName: "Saudi Arabia",
    iso2: "SA",
    iso3: "SAU",
    numericPhonePrefix: 966,
    phone: "966",
  },
  {
    countryName: "Senegal",
    iso2: "SN",
    iso3: "SEN",
    numericPhonePrefix: 221,
    phone: "221",
  },
  {
    countryName: "Serbia",
    iso2: "RS",
    iso3: "SRB",
    numericPhonePrefix: 381,
    phone: "381",
  },
  {
    countryName: "Seychelles",
    iso2: "SC",
    iso3: "SYC",
    numericPhonePrefix: 248,
    phone: "248",
  },
  {
    countryName: "Sierra Leone",
    iso2: "SL",
    iso3: "SLE",
    numericPhonePrefix: 232,
    phone: "232",
  },
  {
    countryName: "Singapore",
    iso2: "SG",
    iso3: "SGP",
    numericPhonePrefix: 65,
    phone: "65",
  },
  {
    countryName: "Sint Maarten",
    iso2: "SX",
    iso3: "SXM",
    numericPhonePrefix: 1,
    phone: "1-721",
  },
  {
    countryName: "Slovakia",
    iso2: "SK",
    iso3: "SVK",
    numericPhonePrefix: 421,
    phone: "421",
  },
  {
    countryName: "Slovenia",
    iso2: "SI",
    iso3: "SVN",
    numericPhonePrefix: 386,
    phone: "386",
  },
  {
    countryName: "Solomon Islands",
    iso2: "SB",
    iso3: "SLB",
    numericPhonePrefix: 677,
    phone: "677",
  },
  {
    countryName: "Somalia",
    iso2: "SO",
    iso3: "SOM",
    numericPhonePrefix: 252,
    phone: "252",
  },
  {
    countryName: "South Africa",
    iso2: "ZA",
    iso3: "ZAF",
    numericPhonePrefix: 27,
    phone: "27",
  },
  {
    countryName: "South Korea",
    iso2: "KR",
    iso3: "KOR",
    numericPhonePrefix: 82,
    phone: "82",
  },
  {
    countryName: "South Sudan",
    iso2: "SS",
    iso3: "SSD",
    numericPhonePrefix: 211,
    phone: "211",
  },
  {
    countryName: "Spain",
    iso2: "ES",
    iso3: "ESP",
    numericPhonePrefix: 34,
    phone: "34",
  },
  {
    countryName: "Sri Lanka",
    iso2: "LK",
    iso3: "LKA",
    numericPhonePrefix: 94,
    phone: "94",
  },
  {
    countryName: "Sudan",
    iso2: "SD",
    iso3: "SDN",
    numericPhonePrefix: 249,
    phone: "249",
  },
  {
    countryName: "Suriname",
    iso2: "SR",
    iso3: "SUR",
    numericPhonePrefix: 597,
    phone: "597",
  },
  {
    countryName: "Svalbard and Jan Mayen",
    iso2: "SJ",
    iso3: "SJM",
    numericPhonePrefix: 47,
    phone: "47",
  },
  {
    countryName: "Swaziland",
    iso2: "SZ",
    iso3: "SWZ",
    numericPhonePrefix: 268,
    phone: "268",
  },
  {
    countryName: "Sweden",
    iso2: "SE",
    iso3: "SWE",
    numericPhonePrefix: 46,
    phone: "46",
  },
  {
    countryName: "Switzerland",
    iso2: "CH",
    iso3: "CHE",
    numericPhonePrefix: 41,
    phone: "41",
  },
  {
    countryName: "Syria",
    iso2: "SY",
    iso3: "SYR",
    numericPhonePrefix: 963,
    phone: "963",
  },
  {
    countryName: "Taiwan",
    iso2: "TW",
    iso3: "TWN",
    numericPhonePrefix: 886,
    phone: "886",
  },
  {
    countryName: "Tajikistan",
    iso2: "TJ",
    iso3: "TJK",
    numericPhonePrefix: 992,
    phone: "992",
  },
  {
    countryName: "Tanzania",
    iso2: "TZ",
    iso3: "TZA",
    numericPhonePrefix: 255,
    phone: "255",
  },
  {
    countryName: "Thailand",
    iso2: "TH",
    iso3: "THA",
    numericPhonePrefix: 66,
    phone: "66",
  },
  {
    countryName: "Togo",
    iso2: "TG",
    iso3: "TGO",
    numericPhonePrefix: 228,
    phone: "228",
  },
  {
    countryName: "Tokelau",
    iso2: "TK",
    iso3: "TKL",
    numericPhonePrefix: 690,
    phone: "690",
  },
  {
    countryName: "Tonga",
    iso2: "TO",
    iso3: "TON",
    numericPhonePrefix: 676,
    phone: "676",
  },
  {
    countryName: "Trinidad and Tobago",
    iso2: "TT",
    iso3: "TTO",
    numericPhonePrefix: 1,
    phone: "1-868",
  },
  {
    countryName: "Tunisia",
    iso2: "TN",
    iso3: "TUN",
    numericPhonePrefix: 216,
    phone: "216",
  },
  {
    countryName: "Turkey",
    iso2: "TR",
    iso3: "TUR",
    numericPhonePrefix: 90,
    phone: "90",
  },
  {
    countryName: "Turkmenistan",
    iso2: "TM",
    iso3: "TKM",
    numericPhonePrefix: 993,
    phone: "993",
  },
  {
    countryName: "Turks and Caicos Islands",
    iso2: "TC",
    iso3: "TCA",
    numericPhonePrefix: 1,
    phone: "1-649",
  },
  {
    countryName: "Tuvalu",
    iso2: "TV",
    iso3: "TUV",
    numericPhonePrefix: 688,
    phone: "688",
  },
  {
    countryName: "U.S. Virgin Islands",
    iso2: "VI",
    iso3: "VIR",
    numericPhonePrefix: 1,
    phone: "1-340",
  },
  {
    countryName: "Uganda",
    iso2: "UG",
    iso3: "UGA",
    numericPhonePrefix: 256,
    phone: "256",
  },
  {
    countryName: "Ukraine",
    iso2: "UA",
    iso3: "UKR",
    numericPhonePrefix: 380,
    phone: "380",
  },
  {
    countryName: "United Arab Emirates",
    iso2: "AE",
    iso3: "ARE",
    numericPhonePrefix: 971,
    phone: "971",
  },
  {
    countryName: "United Kingdom",
    iso2: "GB",
    iso3: "GBR",
    numericPhonePrefix: 44,
    phone: "44",
  },
  {
    countryName: "United States",
    iso2: "US",
    iso3: "USA",
    numericPhonePrefix: 1,
    phone: "1",
  },
  {
    countryName: "Uruguay",
    iso2: "UY",
    iso3: "URY",
    numericPhonePrefix: 598,
    phone: "598",
  },
  {
    countryName: "Uzbekistan",
    iso2: "UZ",
    iso3: "UZB",
    numericPhonePrefix: 998,
    phone: "998",
  },
  {
    countryName: "Vanuatu",
    iso2: "VU",
    iso3: "VUT",
    numericPhonePrefix: 678,
    phone: "678",
  },
  {
    countryName: "Vatican",
    iso2: "VA",
    iso3: "VAT",
    numericPhonePrefix: 39,
    phone: "379",
  },
  {
    countryName: "Venezuela",
    iso2: "VE",
    iso3: "VEN",
    numericPhonePrefix: 58,
    phone: "58",
  },
  {
    countryName: "Vietnam",
    iso2: "VN",
    iso3: "VNM",
    numericPhonePrefix: 84,
    phone: "84",
  },
  {
    countryName: "Wallis and Futuna",
    iso2: "WF",
    iso3: "WLF",
    numericPhonePrefix: 681,
    phone: "681",
  },
  {
    countryName: "Western Sahara",
    iso2: "EH",
    iso3: "ESH",
    numericPhonePrefix: 212,
    phone: "212",
  },
  {
    countryName: "Yemen",
    iso2: "YE",
    iso3: "YEM",
    numericPhonePrefix: 967,
    phone: "967",
  },
  {
    countryName: "Zambia",
    iso2: "ZM",
    iso3: "ZMB",
    numericPhonePrefix: 260,
    phone: "260",
  },
  {
    countryName: "Zimbabwe",
    iso2: "ZW",
    iso3: "ZWE",
    numericPhonePrefix: 263,
    phone: "263",
  }
]
