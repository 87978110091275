/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ManageApiKeysModalContentQueryVariables = {
    id: string;
};
export type ManageApiKeysModalContentQueryResponse = {
    readonly listBrandIngestTokens: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly token: string;
                readonly addTags: ReadonlyArray<string>;
            } | null;
        } | null>;
    } | null;
};
export type ManageApiKeysModalContentQuery = {
    readonly response: ManageApiKeysModalContentQueryResponse;
    readonly variables: ManageApiKeysModalContentQueryVariables;
};



/*
query ManageApiKeysModalContentQuery(
  $id: ID!
) {
  listBrandIngestTokens(id: $id, tags: ["public-api"]) {
    edges {
      node {
        id
        token
        addTags
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Literal",
        "name": "tags",
        "value": [
          "public-api"
        ]
      }
    ],
    "concreteType": "BrandIngestTokenNodeConnection",
    "kind": "LinkedField",
    "name": "listBrandIngestTokens",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandIngestTokenNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandIngestTokenNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addTags",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageApiKeysModalContentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageApiKeysModalContentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "21aeae973eb0d2cd5241fc10e06374e6",
    "id": null,
    "metadata": {},
    "name": "ManageApiKeysModalContentQuery",
    "operationKind": "query",
    "text": "query ManageApiKeysModalContentQuery(\n  $id: ID!\n) {\n  listBrandIngestTokens(id: $id, tags: [\"public-api\"]) {\n    edges {\n      node {\n        id\n        token\n        addTags\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '394cd3eef223865e414265e172b7f849';
export default node;
