import {Environment, Network, RecordSource, Store} from "relay-runtime";
import {authTokenKey, IGNORED_ERRORS_CONFIG} from "./Consts";
import {getOnixUrl} from "./ui-kit/src/commons/http";
import {isErrorIgnored} from "./ui-kit/src/ignoreGraphqlError";

const store = new Store(new RecordSource());
// IMPORTANT !!!
// Get the onix graphql URL from the env variable. Make sure to have
// REACT_APP_ONIX_BACKEND_ENDPOINT defined in your env variables.
// Guide to how you can add custom env variables in your React App.
// Append REACT_APP_ to your env variable name if you want it to be picked up by React.
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
// console.log(JSON.stringify(process.env));
let graphQLUrl = getOnixUrl("/graphql/")
// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery(operation,
                    variables,) {

  // get the auth token
  let authToken = localStorage.getItem(authTokenKey);
  // make sure we don't pass the authToken null, will throw a 401 error.
  if (authToken === null) {
    authToken = ""
  }
  if (!graphQLUrl) {
    graphQLUrl = getOnixUrl("/graphql/");
  }

  return fetch(graphQLUrl, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      // Add authentication and other headers here
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': "JWT " + authToken
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  }).then(response => {
    return response.json();
  }).then(respJson => {
    if (respJson.errors && respJson.errors.length > 0 && !isErrorIgnored(IGNORED_ERRORS_CONFIG, operation.name, respJson.errors)) {
      throw respJson.errors;
    }
    return respJson;
  });
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);

const environment = new Environment({
  network,
  store,
});

export default environment;


export function setAuthToken(token) {
  localStorage.setItem(authTokenKey, token);
}

export function deleteAuthToken() {
  localStorage.removeItem(authTokenKey);
}
