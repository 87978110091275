import React, {useState} from 'react'
import {FormGroup, Col, Row, Form} from "reactstrap";
import styles from './CreatingAccountModal.module.scss'
import HorizontalLabelInputPair from "./HorizontalLabelInputPair";
import CountryPrefixInput, {doesFlagExist} from "../../../dataInput/CountryPrefixInput/CountryPrefixInput";
import {ErrorAlert} from "../../../commons/errors";
import {PLACEHOLDER_VAT} from "./CreatingAccountModal";
import {UserRegistrationDataType} from "./types";

const onVatInputChange = (stateVal: string, editedVal: string, onChange: (val: string) => void) => {
  if (doesFlagExist(stateVal.slice(0, 2)) || stateVal.slice(0, 2) === PLACEHOLDER_VAT) {
    if (editedVal.length <= 2) {
      onChange(stateVal.slice(0, 2));
    } else if (stateVal.slice(0, 2) !== editedVal.slice(0, 2)) {
      onChange(stateVal);
    } else {
      onChange(`${stateVal.slice(0, 2)}${editedVal.slice(2)}`);
    }
  } else {
    onChange(editedVal);
  }
}

type CompanyInformationFormProps = {
  state: UserRegistrationDataType,
  onChange: (val: {property: string, value: string | boolean}) => void,
  handleSubmit: () => void,
  claimErrors: string | null
}

function CompanyInformationForm({state, onChange, handleSubmit, claimErrors = null}: CompanyInformationFormProps) {
  const [isVatOnFocus, setIsVatOnFocus] = useState(false);
  const [isVatInvalid, setIsVatInvalid] = useState(false);
  const [isCountryInvalid, setIsCountryInvalid] = useState(false);
  const isVatIncomplete = state.vatCvr.length <= 3;
  const isCountryIncomplete = !doesFlagExist(state.vatCvr.slice(0, 2));

  return <Row className={"mx-0"}>
    <Col className={"px-0 " + styles.formContainer}>
      <div className={'mx-5'}>
        <Form onSubmit={handleSubmit} action={''}>
          <FormGroup>
            <HorizontalLabelInputPair onChange={value => onChange({property: "companyName", value: value})}
                                      value={state.companyName}
                                      isRequired={true}
                                      name={"Company name"}
                                      containerClasses={"mb-3"}
                                      inputClasses={"ml-3 " + styles.formControl}
                                      labelClasses={styles.labelInput}
                                      autoComplete={false}
                                      data-testid={"register-input-company-name"}/>
            <HorizontalLabelInputPair onChange={value => onVatInputChange(state.vatCvr, value, (newVat) => onChange({property: "vatCvr", value: newVat}))}
                                      value={state.vatCvr}
                                      isRequired={true}
                                      name={"VAT number"}
                                      containerClasses={"mb-3"}
                                      inputClasses={"ml-3 " + styles.vatFormControl}
                                      labelClasses={styles.labelInput}
                                      setFocus={isVatOnFocus}
                                      onBlur={() => {
                                        setIsVatOnFocus(false)
                                        setIsCountryInvalid(isCountryIncomplete)
                                        setIsVatInvalid(isVatIncomplete);
                                      }}
                                      invalid={isVatInvalid}
                                      data-testid={"register-input-vat-number"}>
              <CountryPrefixInput prefixType={'iso2'}
                                  value={state.vatCvr.slice(0, 2).toUpperCase()}
                                  onChange={value => {
                                    let newVat;
                                    if(doesFlagExist(state.vatCvr.slice(0, 2)) || state.vatCvr.slice(0, 2) === PLACEHOLDER_VAT) {
                                      newVat = `${value}${state.vatCvr.slice(2)}`;
                                    } else {
                                      newVat = `${value}${state.vatCvr}`;
                                    }
                                    onChange({property: "vatCvr", value: newVat});
                                    setIsVatOnFocus(true);
                                    setIsCountryInvalid(false);
                                  }}
                                  onClickCapture={(e) => {
                                    e.preventDefault();
                                    setIsCountryInvalid(false);
                                  }}
                                  isInvalid={isCountryInvalid}
                                  className={'ml-3'}
                                  data-testid={"register-dropdown-vat-country"}/>
            </HorizontalLabelInputPair>
            <HorizontalLabelInputPair onChange={value => onChange({property: "address", value: value})}
                                      value={state.address}
                                      isRequired={true}
                                      name={"Address"}
                                      containerClasses={"mb-3"}
                                      inputClasses={"ml-3 " + styles.formControl}
                                      labelClasses={styles.labelInput}
                                      data-testid={"register-input-address"}/>
            <HorizontalLabelInputPair onChange={value => onChange({property: "postNumber", value: value})}
                                      value={state.postNumber}
                                      isRequired={true}
                                      name={"Postal code"}
                                      containerClasses={"mb-3"}
                                      inputClasses={"ml-3 " + styles.formControl}
                                      labelClasses={styles.labelInput}
                                      data-testid={"register-input-postal-code"}/>
            <HorizontalLabelInputPair onChange={value => onChange({property: "city", value: value})}
                                      value={state.city}
                                      isRequired={true}
                                      name={"City"}
                                      containerClasses={"mb-3"}
                                      inputClasses={"ml-3 " + styles.formControl}
                                      labelClasses={styles.labelInput}
                                      data-testid={"register-input-city"}/>
          </FormGroup>
          <button className={'d-none'} type={'submit'}/>
        </Form>
        <ErrorAlert error={claimErrors} />
      </div>
    </Col>
  </Row>
}

export default CompanyInformationForm
