import React from 'react'
import Tooltip from '../../src/atoms/Tooltip'
import styles from './SidebarComponents.module.scss'
import addClassNames from '../../src/classNameUtils'
import {Link} from "react-router-dom";

export function SidebarTitle({title}: {title: string}) {
  return <li className={styles.navTitle}>
    {title}
  </li>;
}

type SidebarExternalLinkProps = {
  title: string,
  icon: string,
  link: string,
  iconFontSize?: string,
  disabled?: boolean,
  dataTestId?: string
}

export function SidebarExternalLink({title, icon, link, iconFontSize, disabled = false, dataTestId}: SidebarExternalLinkProps) {
  return <li className={styles.linkContainer}>
    <a href={!disabled ? link : undefined}
       target="_blank"
       rel="noopener noreferrer"
       className={addClassNames([
         {className: styles.link, condition: true},
         {className: styles.disabledLink, condition: disabled}
       ])}
       data-testid={dataTestId}>
      <Tooltip text={title}
               delay={0}
               placement={"right"}
               wrapperClassName={styles.iconTooltipWrapper}>
        <i className={addClassNames([
          {className: icon, condition: true},
          {className: styles.icon, condition: true},
          {className: styles.inactiveIcon, condition: true},
        ])} style={{fontSize: iconFontSize}}/>
      </Tooltip>
      <i className={addClassNames([
        {className: icon, condition: true},
        {className: styles.noTooltipIcon, condition: true},
        {className: styles.icon, condition: true},
        {className: styles.inactiveIcon, condition: true},
      ])} style={{fontSize: iconFontSize}}/>
      <span className={styles.linkTitle}>{title}</span>
    </a>
  </li>
}

type SidebarLinkProps = {
  title: string,
  icon: string,
  link: string,
  location: {
    pathname: string
  },
  iconFontSize?: string,
  disabled?: boolean,
  dataTestId?: string
}

export function SidebarLink({title, icon, link, location, iconFontSize, disabled = false, dataTestId}: SidebarLinkProps) {
  const isActive = () => {
    const currentPath = location.pathname.split("/");
    const targetPath = link.split("/");

    if (targetPath.length > currentPath.length) {
      return false;
    }

    for (let i = 0; i < targetPath.length; i++) {
      if (currentPath[i] !== targetPath[i]) {
        return false;
      }
    }

    return true;
  };

  return <li className={styles.linkContainer}>
    <Link to={!disabled ? link : location}
          className={addClassNames([
            {className: styles.link, condition: true},
            {className: styles.activeLink, condition: isActive()},
            {className: styles.disabledLink, condition: disabled}
          ])}
          data-testid={dataTestId}>
      <Tooltip text={title}
               delay={0}
               placement={"right"}
               wrapperClassName={styles.iconTooltipWrapper}>
        <i className={addClassNames([
          {className: icon, condition: true},
          {className: styles.icon, condition: true},
          {className: styles.activeIcon, condition: isActive()},
          {className: styles.inactiveIcon, condition: !isActive()},
        ])} style={{fontSize: iconFontSize}}/>
      </Tooltip>
      <i className={addClassNames([
        {className: icon, condition: true},
        {className: styles.noTooltipIcon, condition: true},
        {className: styles.icon, condition: true},
        {className: styles.activeIcon, condition: isActive()},
        {className: styles.inactiveIcon, condition: !isActive()},
      ])} style={{fontSize: iconFontSize}}/>
      <span className={styles.linkTitle}>{title}</span>
    </Link>
  </li>
}

export function SidebarSeparator() {
  return <li className={styles.separator}/>
}
