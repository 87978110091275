import React, {HTMLAttributes} from "react";
import {FormGroup} from "reactstrap";
import {DefaultInput} from "../../../atoms/Input";

type HorizontalLabelInputPairProps = {
  name: string,
  value: string,
  onChange: (val: string) => void,
  containerClasses?: string,
  labelClasses?: string,
  inputClasses?: string,
  inputType?: 'text' | 'password' | 'email',
  isRequired: boolean,
  autoComplete?: boolean,
  setFocus?: boolean,
  children?: JSX.Element | JSX.Element[],
  invalid?: boolean
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>;


export default function HorizontalLabelInputPair({
                                                   name,
                                                   value,
                                                   onChange,
                                                   containerClasses,
                                                   labelClasses,
                                                   inputClasses,
                                                   inputType,
                                                   isRequired,
                                                   autoComplete = true,
                                                   setFocus = false,
                                                   children,
                                                   ...rest
                                                 }: HorizontalLabelInputPairProps) {
  return <FormGroup className={"d-flex align-items-center " + containerClasses}>
    <label className={labelClasses}>{name}</label>
    {children}
    <DefaultInput value={value}
           className={inputClasses}
           type={inputType ? inputType : "text"}
           required={isRequired}
           onChange={(e) => {
             onChange(e.target.value)
           }}
           // 'new-password' disables autocomplete on all browsers
           autoComplete={autoComplete ? '' : 'new-password'}
           setFocus={setFocus}
           {...rest}
    />
  </FormGroup>
}
