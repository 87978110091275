import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";
import {CreateBrandApiKeyMutation} from "./__generated__/CreateBrandApiKeyMutation.graphql";

const mutation = graphql`
    mutation CreateBrandApiKeyMutation($input: CreateBrandIngestTokenMutationInput!) {
        createBrandIngestToken(input: $input) {
            clientMutationId
            instance {
                id
                token
                addTags
            }
        }
    }
`

export default createMutationBoilerplate<CreateBrandApiKeyMutation>(mutation);
