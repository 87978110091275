import React from "react";
import {Row} from "reactstrap";
import styles from "./ResetPasswordConfirmationModalContent.module.scss"
import checkmark from "../../../assets/circle-checkmark.png"
import {useHistory} from "react-router";

export default function ResetPasswordConfirmationModalContent({isTriggerConfirmation = true}) {
  const history = useHistory()

  return <div className={styles.containerElement}>
    <Row className={"mx-0"}>
      <i className={"fa-regular fa-xmark " + styles.closeIcon} onClick={() => history.push('/login')}/>
    </Row>
    <Row className={"justify-content-center mx-0 mb-3"}>
      <img src={checkmark} alt={"checkmark"} className={styles.checkMarkCircle}/>
    </Row>
    <Row className={"justify-content-center mx-0"}>
      <h4 className={styles.headerText}>
        {isTriggerConfirmation ?
          "Check your email" :
          "Password changed"}
      </h4>
      <p className={styles.subText}>
        {isTriggerConfirmation ?
          <p>Instructions for resetting your password have been sent to the provided email. Please make sure to check your <b>SPAM</b> folder in case you do not see an email.</p> :
          "You can now sign in with your new password."
        }
      </p>
    </Row>
  </div>
}
