import React from "react";
import {Button as ReactstrapButton} from "reactstrap";
import styles from './MidButton.module.scss';

function MidButton({className, loading, children, block, variant = "primary", bars = false, caret = false, ...props}) {

  let subVariation = className + ' ' + styles.midButton + " " + (variant === "primary" ? styles.midPrimary : styles.midSecondary)

  return <ReactstrapButton disabled={loading}
                           block={block}
                           className={subVariation}
                           color={'black'}
                           size={'sm'}
                           {...props}>
    {loading && <i className="fas fa-circle-notch fa-spin mr-2"/>}
    {bars && <i className="fas fa-bars mr-2"/>}
    {children}
    {caret && <i className="fas fa-caret-down ml-2"/>}
  </ReactstrapButton>
}

export default MidButton;
