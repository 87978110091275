import React, {ReactNode, useCallback, useContext, useState} from 'react';
import {noop} from "../../commons/misc";

type DropdownContextType = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  currentSubmenuIndex: number | null,
  setCurrentSubmenuIndex: (index: number | null) => void,
  toggleDropdowns: () => void,
  closeAllDropdowns: () => void,
  keepOpenAfterSelect: boolean
  dropdownRef: React.RefObject<HTMLDivElement> | null,
  setRef: (ref: React.RefObject<HTMLDivElement> | null) => void
}

const DropdownContext = React.createContext<DropdownContextType>({
  setRef(ref: React.RefObject<HTMLDivElement> | null): void {
  },
  isOpen: false,
  dropdownRef: null,
  setIsOpen: noop,
  currentSubmenuIndex: null,
  setCurrentSubmenuIndex: noop,
  toggleDropdowns: noop,
  closeAllDropdowns: noop,
  keepOpenAfterSelect: false
});


type DropdownContextProviderProps = {
  children: ReactNode,
  preToggle: boolean,
  keepOpenAfterSelect?: boolean
};

export function DropdownContextProvider({
                                          children,
                                          preToggle,
                                          keepOpenAfterSelect = false
                                        }: DropdownContextProviderProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(preToggle)
  const [dropdownRef, setDropdownRef] = useState<React.RefObject<HTMLDivElement> | null>(null)
  const [currentSubmenuIndex, setCurrentSubmenuIndex] = useState<number | null>(null);

  const setRef = (ref: React.RefObject<HTMLDivElement> | null) => {
    if (!dropdownRef && ref) {
      setDropdownRef(ref)
    }
  }

  const toggleDropdowns = useCallback(() => {
    setIsOpen(!isOpen)
    setCurrentSubmenuIndex(null);
  }, [isOpen])

  const closeAllDropdowns = useCallback(() => {
    setIsOpen(false);
    setCurrentSubmenuIndex(null);
  }, [])

  return <DropdownContext.Provider
    value={{
      isOpen,
      setRef,
      dropdownRef,
      setIsOpen,
      currentSubmenuIndex,
      setCurrentSubmenuIndex,
      toggleDropdowns,
      closeAllDropdowns,
      keepOpenAfterSelect,
    }}>
    {children}
  </DropdownContext.Provider>
}

export function useDropdownContext(): DropdownContextType {
  return useContext<DropdownContextType>(DropdownContext);
}
