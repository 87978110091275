import {createMutationBoilerplate} from "../commons/graphql";
import {graphql} from "react-relay";
import {TriggerPasswordResetFlowMutation} from "./__generated__/TriggerPasswordResetFlowMutation.graphql";

export default createMutationBoilerplate<TriggerPasswordResetFlowMutation>(
  graphql`
    mutation TriggerPasswordResetFlowMutation($input: TriggerPasswordResetFlowMutationInput!) {
      triggerPasswordResetFlow(input: $input) {
        success
      }
    }
  `
)
