import React, {useState} from "react";
import styles from "./AddCompanyModal.module.scss";
import {Input, Row} from "reactstrap";
import GrayModal, {ButtonSpec} from "../ui-kit/src/dataDisplay/GrayModal";
import CreateBrandMutation from "../mutations/CreateBrandMutation";
import environment from "../Environment";

export const COMPANY_STEP = 0
export const CONFIRMATION_STEP = 1
const STEPS = [{stepText: "Brand name"}, {stepText: "Done"}]

type AddCompanyModalProps = {
  isModalOpen: boolean,
  id: string,
  toggle: () => void
}

export default function AddCompanyModal({isModalOpen, id, toggle}: AddCompanyModalProps) {
  const [step, setStep] = useState(COMPANY_STEP)
  const [name, setName] = useState("")
  const [error, setError] = useState<Error | null>(null)

  const errorContent = <div className={"p-4 my-2 w-100"}>
    <Row className={"d-inline-flex align-items-center justify-content-center mx-0 mb-3"}>
      <h3 className={"m-0 pr-2 " + styles.errorText}>
        Something went wrong. please try again
      </h3>
      <i className={"fas fa-exclamation-circle " + styles.errorText} style={{fontSize: 24}}/>
    </Row>
    <Row className={"mx-0"}>
      <p className={styles.errorText}>
        {error?.message}
      </p>
    </Row>
  </div>

  let bodyContent
  let primaryButton: ButtonSpec | undefined;
  if (step === COMPANY_STEP) {
    bodyContent = <div className={"p-4 my-2 ml-4"}>
      <Input
        className={styles.input}
        placeholder={"Type the brand name"}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </div>

    primaryButton = {
      isDisabled: !name,
      text: "Create", onClick: () => {
        CreateBrandMutation(
          environment,
          {
            name: name,
            companyId: id
          },
          () => {
            setStep(CONFIRMATION_STEP)
          },
          err => setError(err)
        )
      }
    }
  } else {
    bodyContent = <div className={"p-4 ml-4"}>
      <p className={"m-0"}>
        Manage your brand or add another one via the menu in the top right corner.
      </p>
    </div>
  }

  if (error) {
    bodyContent = errorContent;
    primaryButton = undefined;
  }

  return <GrayModal toggle={toggle}
                    isOpen={isModalOpen}
                    className={styles.modal}
                    primaryHeaderButton={primaryButton}
                    secondaryHeaderButton={(step === CONFIRMATION_STEP && !error) ?
                      {
                        text: "Close",
                        onClick: () => {
                          window.location.href = '/product/collections'
                        }
                      } :
                      {text: "Cancel", onClick: toggle}}
                    title={"Add a brand"}
                    steps={STEPS}
                    stepsIndex={step}
                    bodyContent={bodyContent}
  />
}
