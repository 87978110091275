import React from 'react';
import styles from './SuccessModalStep.module.scss';
import greenCircleCheck from "../../../assets/svg/greenCircleCheck.svg";

export default function SuccessModalStep({toggle}: {toggle: () => void})  {
  return <div className={styles.container}>
    <div onClick={toggle} className={styles.toggleIconContainer}>
      <i className={`fa-regular fa-xmark ${styles.toggleIcon}`} />
    </div>
    <img src={greenCircleCheck} alt={"greenCircleCheck"}/>
    <h1 className={styles.title}>Welcome to Vuuh!</h1>
    <h4 className={styles.subtitle}>Your account has been created, which means<br/> you are now in good hands!</h4>
  </div>
}
