import React from 'react';
import Dropdown from "../../atoms/Dropdown";
import {countriesData, getCountryData} from "./CountriesData";
import placeholderFlag from './CountryFlagsIcons/placeholder.png';
import addClassNames from "../../classNameUtils";
import styles from './CountryPrefixInput.module.scss';

const isValidImage = (imagePath: string) => {
  try {
    return require(`${imagePath}`);
  } catch (err) {
    return null;
  }
};

export const doesFlagExist = (iso2: string) => {
  const loweCaseIso2 = iso2 && iso2.toLowerCase();
  return !!isValidImage(`./CountryFlagsIcons/${loweCaseIso2}.png`);
}

const PlaceholderFlag = () => <img src={placeholderFlag} alt={'Flag'} style={{width: '0.85rem'}}/>;

function getFlagIcon(iso2: string) {
  const loweCaseIso2 = iso2 && iso2.toLowerCase();
  const upperCaseIso2 = iso2 && iso2.toUpperCase();
  return doesFlagExist(loweCaseIso2)
    ? <img src={require(`./CountryFlagsIcons/${loweCaseIso2}.png`)} alt={upperCaseIso2} style={{width: '0.85rem'}}/>
    : <PlaceholderFlag />;
}

type CountryPrefixInputType = {
  prefixType: 'iso2' | 'iso3' | 'phone'
  value: string | null,
  onChange: (val: string) => void,
  isInvalid?: boolean,
  className?: string
} & React.ButtonHTMLAttributes<any>

export default function CountryPrefixInput({prefixType, value, onChange, isInvalid = false, className = '', ...rest}: CountryPrefixInputType) {
  const countryData = value ? getCountryData(value, prefixType) : null;

  return <Dropdown
    currentValue={{label: countryData ? getFlagIcon(countryData.iso2) : <PlaceholderFlag />}}
    options={countriesData.map(country => ({
      label: country.countryName,
      value: country[prefixType],
      icon: getFlagIcon(country.iso2)
    })) }
    menuWidth={'15.5rem'}
    onSelectOption={op => op.value && onChange(op.value)}
    keepOpenAfterSelect={false}
    className={addClassNames([
      {className: className, condition: true},
      {className: styles.invalid, condition: isInvalid}
    ])}
    {...rest}/>
}
