import {ourToast} from '../ui-kit/src/atoms/Toast';
import changeCurrentBrand from "../mutations/ChangeCurrentBrand";
import environment from "../Environment";

export function handleBrandChange(brand) {
  changeCurrentBrand(
    environment,
    {
      brandId: brand.id
    },
    () => {
      window.location.reload();
    },
    err => {
      ourToast('error', err[0].message);
    }
  )
}
