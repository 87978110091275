import React, {useState} from "react";
import Dropdown from "../../atoms/Dropdown";
import {Nav} from "reactstrap";
import styles from "./UserSettingsDropdown.module.scss"
import Button from "../../atoms/Button";
import addClassNames from "../../classNameUtils";

const USER_SETTINGS_DROPDOWN_OPTIONS = {
  SETTINGS: "settings",
  SIGN_OUT: "sign_out"
}

type UserSettingsDropdownProps = {
  userName: string,
  userEmail: string,
  handleSettings: () => void,
  handleLogOut: () => void
}

export default function UserSettingsDropdown({
                                               userName,
                                               userEmail,
                                               handleSettings,
                                               handleLogOut
                                             }: UserSettingsDropdownProps) {
  let options = [
    {
      label: <label className={`mb-0 ${styles.smallFont}`} style={{cursor: "inherit"}}>Account Settings</label>,
      value: USER_SETTINGS_DROPDOWN_OPTIONS.SETTINGS,
      icon: <i className={`fa-light fa-sliders ${styles.dropdownMenuIcon}`}/>,
      className: styles.dropdownOptionHover
    },
    {divider: true},
    {
      label: <label className={`mb-0 ${styles.smallFont}`} style={{cursor: "inherit"}}>Sign Out</label>,
      value: USER_SETTINGS_DROPDOWN_OPTIONS.SIGN_OUT,
      icon: <i className={`fa-light fa-right-from-bracket ${styles.dropdownMenuIcon}`}/>,
      className: styles.dropdownOptionHover
    },
  ]

  return <Nav style={{marginRight: '2rem'}} navbar>
    <Dropdown currentValue={{label: ""}}
              options={options}
              onSelectOption={option => {
                if (option.value === USER_SETTINGS_DROPDOWN_OPTIONS.SETTINGS) {
                  handleSettings()
                } else if (option.value === USER_SETTINGS_DROPDOWN_OPTIONS.SIGN_OUT) {
                  handleLogOut()
                }
              }}
              ToggleTag={(props) => {
                return <Button onClick={e => props.toggleDropdowns(e)}
                               className={addClassNames([
                                 {className: styles.dropdownMenuButton, condition: true},
                                 {className: styles.dropdownMenuButtonActive, condition: props.isOpen},
                               ])}
                               color={'transparentSecondary'}>
                  <i className={`fa-regular fa-user ${styles.userIcon}`}/>
                  <p className={"mb-0 " + styles.smallFont}>
                    {(userName && !!userName.replace(/\s/g, '').length) ? userName : userEmail}
                  </p>
                  <i className={`ml-2 mr-0 fa-solid fa-chevron-down ${styles.chevronIcon}`}/>
                </Button>
              }}
    />
  </Nav>
}
