/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteBrandIngestTokenMutationInput = {
    token: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteBrandApiKeyMutationVariables = {
    input: DeleteBrandIngestTokenMutationInput;
};
export type DeleteBrandApiKeyMutationResponse = {
    readonly deleteBrandIngestToken: {
        readonly clientMutationId: string | null;
        readonly success: boolean | null;
    } | null;
};
export type DeleteBrandApiKeyMutation = {
    readonly response: DeleteBrandApiKeyMutationResponse;
    readonly variables: DeleteBrandApiKeyMutationVariables;
};



/*
mutation DeleteBrandApiKeyMutation(
  $input: DeleteBrandIngestTokenMutationInput!
) {
  deleteBrandIngestToken(input: $input) {
    clientMutationId
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteBrandIngestTokenMutationPayload",
    "kind": "LinkedField",
    "name": "deleteBrandIngestToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteBrandApiKeyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteBrandApiKeyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b2f0cf3e4835d08142eea4d3f6e41b77",
    "id": null,
    "metadata": {},
    "name": "DeleteBrandApiKeyMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteBrandApiKeyMutation(\n  $input: DeleteBrandIngestTokenMutationInput!\n) {\n  deleteBrandIngestToken(input: $input) {\n    clientMutationId\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '90db0bab1e0c599b9875d49075a703a5';
export default node;
