import React, {InputHTMLAttributes, useEffect, useState} from 'react';
import styles from './Input.module.scss';
import Input from './Input'
import addClassNames from "../classNameUtils";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  delay?: number;
  width?: string;
  value?: string;
  placeholder?: string;
  onClear?: () => void
}

export default function SearchInput(props: Props): JSX.Element {
  const {disabled, onClear, delay, width, value = '', placeholder = 'Search', className, ...rest} = props;
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const classNames = [
    {className: styles.searchInputContainer, condition: true},
    {className: className, condition: !!className},
  ]

  return <div className={addClassNames(classNames)} style={{width: width}}>
    <Input value={inputValue}
           onChange={(e) => setInputValue(e.target.value)}
           placeholder={placeholder}
           disabled={disabled}
           style={{width: width}}
           className={styles.searchInput}
           type={'search'}
           delay={delay}
           {...rest}/>
    <i className={'fas fa-search ' + styles.searchIcon}/>
    {onClear && value && <i className={'fa-solid fa-sm fa-circle-xmark mr-1 ' + styles.clearIcon} onClick={onClear}/>}
  </div>
}
