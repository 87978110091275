import React from "react";
import LoadingOverlay from 'react-loading-overlay'
import {PropagateLoader} from "react-spinners";

export default function MyLoaderOverlay({active, children}) {
  return (
    <LoadingOverlay
      active={active}
      spinner={<PropagateLoader size={20} color={'#8235FF'}/>}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(47,53,58,0)'
        }),
        wrapper: {
          overflow: active ? 'hidden' : 'scroll'
        }
      }}
    >
      {children}
    </LoadingOverlay>
  )
}
