import React, {useContext, useState, useReducer, useEffect} from "react";
import {Modal, Row, Col, Card, CardBody} from "reactstrap";
import UserInfoForm from "./components/UserInfoForm";
import ChangePasswordForm from "./components/ChangePasswordForm";
import BrandForm from "./components/BrandForm";
import UserContext from "../../../context/UserContext";
import PrimaryActionButton from "../../../ui-kit/src/general/PrimaryActionButton";
import styles from "./SettingsModal.module.scss"
import SecondaryActionButton from "../../../ui-kit/src/general/SecondaryActionButton";
import {isEmailFormat} from "../../../ui-kit/src/stringUtils";
import updateUserMutation from "../../../mutations/UpdateUserMutation";
import environment from "../../../Environment";
import updateBrandMutation from "../../../mutations/UpdateBrandMutation";
import {ourToast} from "../../../ui-kit/src/atoms/Toast";
import changePasswordMutation from "../../../mutations/ChangePasswordMutation";

const mutationFlags = {user: false, brand: false, password: false}

export function _errorMessagesReducer(errorMessages, action) {
  if (action.type === "SET_USER_ERROR") {
    return [...errorMessages, {userErrors: action.errors}]
  }
  if (action.type === "SET_BRAND_ERROR") {
    return [...errorMessages, {brandErrors: action.errors}]
  }
  if (action.type === "SET_PASSWORD_ERROR") {
    return [...errorMessages, {passwordErrors: action.errors}]
  }
  if (action.type === "RESET_ERROR_MESSAGES") {
    return []
  }
}

export function _stateChangeReducer(mutationFlags, action) {
  if (action.type === "ACTIVATE") {
    return {...mutationFlags, [action.mutationFlagName]: true}
  }
  if (action.type === "DEACTIVATE") {
    return {...mutationFlags, [action.mutationFlagName]: false}
  }
}

function isAnyoneLoading(state) {
  return (state.user && state.brand && state.password)
}


function SettingsModal({isOpen, toggle, isAdmin = true}) {
  const user = useContext(UserContext);
  const [state, dispatchState] = useReducer(_stateChangeReducer, mutationFlags)
  const [messages, dispatchMessages] = useReducer(_errorMessagesReducer, [])
  const [lastName, setLastName] = useState(user.lastName)
  const [firstName, setFirstName] = useState(user.firstName)
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [brandName, setBrandName] = useState(user.brand.name)
  const [logo, setLogo] = useState(user.brand.logo)
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (!isFirstRender && messages.length === 0) {
      toggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
    }
  }, [isFirstRender],)


  const checkPermission = (permission) => {  //Placed here since this will probably use user.context
    return permission === "CAN_EDIT_BRAND"
  }

  const isAnyFieldTypedInto = () => {
    return (
      firstName !== user.firstName || lastName !== user.lastName || email !== user.email ||
      brandName !== user.brand.name || logo !== user.brand.logo ||
      password.length > 0)
  }

  const activate = (mutationFlagName) => {
    dispatchState({type: "ACTIVATE", mutationFlagName: mutationFlagName});
  }

  const deactivate = (mutationFlagName) => {
    dispatchState({type: "DEACTIVATE", mutationFlagName: mutationFlagName});
  }

  const setErrorMessages = (type, errors) => {
    dispatchMessages({type: type, errors: errors});
  }

  const masterSubmitUpdate = () => {
    dispatchMessages({type: "RESET_ERROR_MESSAGES"});
    if (lastName !== user.lastName || firstName !== user.firstName || email !== user.email) {
      submitUserUpdate();
    }
    if (password) {
      submitPasswordChange();
    }
    if (brandName !== user.brand.name || logo !== user.brand.logo) {
      submitBrandUpdate();
    }
  }

  const submitUserUpdate = () => {
    if (!isEmailFormat(email)) {
      setErrorMessages("SET_USER_ERROR", 'This is not a valid email format')
      return;
    }
    activate("user")
    updateUserMutation(
      environment,
      {
        firstName,
        lastName,
        email,
      },
      () => {
        deactivate("user");
      },
      (error) => {
        setErrorMessages("SET_USER_ERROR", error[0].message)
        deactivate("user");
      }
    )
  };

  const submitPasswordChange = () => {
    if (confirmPassword !== password) {
      setErrorMessages("SET_PASSWORD_ERROR", 'Confirming the Password is required')
      return;
    }
    if (password.length < 6) {
      setErrorMessages("SET_PASSWORD_ERROR", 'Password must contain at least 6 characters')
      return;
    }
    activate("password")
    changePasswordMutation(
      environment,
      {
        password,
        confirmPassword
      },
      () => {
        setPassword("");
        setConfirmPassword("");
        deactivate("password")
      },
      (err) => {
        deactivate("password")
        setErrorMessages("SET_PASSWORD_ERROR", err[0].message)
      }
    )
  };

  const submitBrandUpdate = () => {
    activate("brand")
    updateBrandMutation(
      environment,
      {
        brand: user.brand.id,
        name: brandName,
        ...(logo !== user.brand.logo && {logo})
      },
      (res) => {
        deactivate("brand")
        setLogo(res.updateBrand.brand.logo);
        ourToast("success", "Saved");
      },
      (err) => {
        deactivate("brand")
        setErrorMessages("SET_BRAND_ERROR", err[0].message)
      }
    )
  };
  return <Modal style={{minWidth: '750px'}} backdrop={'static'} isOpen={isOpen} toggle={toggle}>
    <Card className={'pb-2 mb-1'}>
      <h4 className={"font-weight-bold m-3 p-1 pb-2"}>Account Settings</h4>
      <hr className={styles.extendedHr + " mt-0 mb-2"}/>
      <CardBody className={'pb-0'}>
        <Col>
          <Row>
            <Col md={5} className={'pr-0'}>
              <UserInfoForm
                brandName={!isAdmin ? brandName : null}
                firstNameValue={firstName}
                onFirstNameUpdate={(value) => {
                  setFirstName(value)
                }}
                lastNameValue={lastName}
                onLastNameUpdate={(value) => {
                  setLastName(value)
                }}
                emailValue={email}
                onEmailUpdate={(value) => {
                  setEmail(value)
                }}
                errors={messages.filter(message => {
                  return message.userErrors
                })}
              />
            </Col>
            {isAdmin && <Col md={{offset: 2, size: 5}} className={'pl-0'}>
              <BrandForm
                hasPermission={checkPermission('CAN_EDIT_BRAND')}
                brandNameValue={brandName}
                onBrandNameUpdate={(value) => {
                  setBrandName(value)
                }}
                logoContent={logo}
                onBrandLogoUpdate={(value) => {
                  setLogo(value)
                }}
                errors={messages.filter(message => {
                  return message.brandErrors
                })}
              />
            </Col>}
          </Row>
        </Col>
        <hr/>
        <Col className={'pt-3'}>
          <Row>
            <Col md={6}>
              <ChangePasswordForm
                passwordValue={password}
                onPasswordUpdate={(value) => {
                  setPassword(value)
                }}
                confirmPasswordValue={confirmPassword}
                onConfirmPasswordUpdate={(value) => {
                  setConfirmPassword(value)
                }}
                errors={messages.filter(message => {
                  return message.passwordErrors
                })}
              />
            </Col>
          </Row>
        </Col>
      </CardBody>
      <hr className={styles.extendedHr + " mb-4"}/>
      <Row className={'d-flex justify-content-end'}>
        <div className={'pr-3'}>
          <SecondaryActionButton
            loading={isAnyoneLoading(state)}
            onClick={() => {
              dispatchMessages({type: "RESET_ERROR_MESSAGES"});
              toggle()
            }}>
            Cancel
          </SecondaryActionButton>
        </div>
        <div className={'pr-4'}>
          <PrimaryActionButton
            id="submit-button"
            disabled={!isAnyFieldTypedInto()}
            loading={isAnyoneLoading(state)}
            onClick={() => {
              masterSubmitUpdate()
            }}>
            Save Changes
          </PrimaryActionButton>
        </div>
      </Row>
    </Card>
  </Modal>
}

export default SettingsModal
