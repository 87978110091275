import React, {ReactNode, useContext} from 'react';


export type Flag =
  'products.image_management' |
  'products.bulk_image_upload' |
  'products.cancel' |
  'products.file_completion' |
  'output.redesign' |
  'brandspage.redesign' |
  'output.image_naming_modal' |
  'images.image_upload_batch'


type FeatureFlagsContextType = {
  flags: Flag[]
}


const FeatureFlagsContext = React.createContext<FeatureFlagsContextType>({
  flags: []
});


type FeatureFlagsProviderProps = {
  flags: Flag[],
  children: ReactNode
}

export function FeatureFlagsProvider({flags, children}: FeatureFlagsProviderProps) {
  return <FeatureFlagsContext.Provider value={{flags}}>
    {children}
  </FeatureFlagsContext.Provider>
}

export function useFeatureFlag(flagName: Flag) {
  const {flags} = useContext(FeatureFlagsContext);
  return {
    active: flags.includes(flagName)
  }
}
