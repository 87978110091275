import {isIterable} from './utilities';

export function removeFromArray(array, toRemove) {
  if (!isIterable(toRemove)) {
    toRemove = [toRemove]
  }
  return array.filter(x => toRemove.indexOf(x) === -1)
}

export function addToArray(array, toAdd) {
  if (!isIterable(toAdd)) {
    toAdd = [toAdd]
  }
  return [...array, ...toAdd];
}


export function ensureNoDuplicates(array) {
  return [...new Set(array)];
}
