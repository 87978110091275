import React from "react";
import CountryPrefixInput from "./CountryPrefixInput";
// @ts-ignore
import {render, screen, logRoles, getRoles, cleanup} from '@testing-library/react';
import '@testing-library/jest-dom/extend-expect'
import {noop} from "../../commons/misc";

const countries = [
  {name: 'Afghanistan', iso2: 'AF', iso3: 'AFG', phonePrefix: '93', flagIcon: 'af.png'},
  {name: 'Germany', iso2: 'DE', iso3: 'DEU', phonePrefix: '49', flagIcon: 'de.png'},
  {name: 'United Kingdom', iso2: 'GB', iso3: 'GBR', phonePrefix: '44', flagIcon: 'gb.png'},
  {name: 'France', iso2: 'FR', iso3: 'FRA', phonePrefix: '33', flagIcon: 'fr.png'},
  {name: 'Italy', iso2: 'IT', iso3: 'ITA', phonePrefix: '39', flagIcon: 'it.png'},
  {name: 'Netherlands', iso2: 'NL', iso3: 'NLD', phonePrefix: '31', flagIcon: 'nl.png'},
  {name: 'Switzerland', iso2: 'CH', iso3: 'CHE', phonePrefix: '41', flagIcon: 'ch.png'},
  {name: 'Poland', iso2: 'PL', iso3: 'POL', phonePrefix: '48', flagIcon: 'pl.png'},
  {name: 'Greece', iso2: 'EL', iso3: 'GRC', phonePrefix: '30', flagIcon: 'el.png'},
  {name: 'Denmark', iso2: 'DK', iso3: 'DNK', phonePrefix: '45', flagIcon: 'dk.png'},
  {name: 'Romania', iso2: 'RO', iso3: 'ROU', phonePrefix: '40', flagIcon: 'ro.png'},
]

describe ('CountryPrefixInput', () => {
  it('renders properly', () => {
    render(<CountryPrefixInput prefixType={'iso2'} value={null} onChange={noop} />)
    const buttonElement = screen.getByRole('button');
    expect(buttonElement).toBeInTheDocument();
    const flagIconElement = screen.getByRole('img');
    expect(flagIconElement.src).toMatch(/^.*placeholder.png/);
  });

  it('renders the correct flag icon', () => {
    countries.forEach(country => {
      render(<CountryPrefixInput prefixType={'iso2'} value={country.iso2} onChange={noop} />)
      const flagIconElement = screen.getByRole('img');
      expect(flagIconElement.src).toMatch(new RegExp(`^.*${country.flagIcon}`));
      cleanup();
    });
    countries.forEach(country => {
      render(<CountryPrefixInput prefixType={'iso3'} value={country.iso3} onChange={noop} />)
      const flagIconElement = screen.getByRole('img');
      expect(flagIconElement.src).toMatch(new RegExp(`^.*${country.flagIcon}`));
      cleanup();
    });
    countries.forEach(country => {
      render(<CountryPrefixInput prefixType={'phone'} value={country.phonePrefix} onChange={noop} />)
      const flagIconElement = screen.getByRole('img');
      expect(flagIconElement.src).toMatch(new RegExp(`^.*${country.flagIcon}`));
      cleanup();
    })
  });

  it('calls onChange function when an option is selected', () => {
    const onChangeMock = jest.fn();
    render(<CountryPrefixInput prefixType={'iso2'} value={null} onChange={onChangeMock} />)
    countries.forEach(country => {
      const buttonElement = screen.getByRole('button');
      buttonElement.click();
      const countryOptionButton = screen.getByText(country.name);
      countryOptionButton.click();
      expect(onChangeMock).toHaveBeenCalled();
    })
  })
})


