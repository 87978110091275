import React from "react";
import Button from './Button';

function SecondaryActionButton({loading, children, block, className, iconLeft, iconRight, ...props}) {
  if (props.size && props.size === 'sm') {
    className += ' text-dark';
  }
  return <Button loading={loading} block={block} color={'secondary'} className={className} {...props}>
    {iconLeft}
    {children}
    {iconRight}
  </Button>
}

export default SecondaryActionButton;
