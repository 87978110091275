import React, {useEffect} from 'react';
import Loadable from 'react-loadable'
import {Redirect} from "react-router-dom";
import {Alert} from 'reactstrap';
import DefaultLayout from './containers/DefaultLayout';
import {useCurrentUser} from "./context/UserContext";
import environment from "./Environment";
import {handleChunkErrors} from "./ui-kit/src/commons/errorHandling";
import AmazonCallbackView from "./ui-kit/src/pages/oauth/AmazonCallbackView";

function Loading({error}) {

  useEffect(() => {
    handleChunkErrors({error: error})
  }, [error])

  if (error) {
    return <Alert color={'danger'}>
      Failed to display: {error.toString()}
    </Alert>
  }
  return <div>Loading...</div>;
}

const ProductsPrototype = Loadable({
  loader: () => import('./views/Products/Products'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;
    return <Component {...props} environment={environment}/>
  }
})

const ProductView = Loadable({
  loader: () => import('./views/Variants/ProductVariantsPage'),
  loading: Loading,
});

const UploadFile = Loadable({
  loader: () => import('./views/_ProductOld/UploadFile/Upload'),
  loading: Loading,
});


const SubscriptionsListV2 = Loadable({
  loader: () => import('./views/Retailers/SubscriptionListViewV2'),
  loading: Loading,
});


const Output = Loadable({
  loader: () => import('./views/Output/OutputPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;
    return <Component {...props} environment={environment}/>
  }
});

const Orders = Loadable({
  loader: () => import('./views/orders/OrdersPage'),
  loading: Loading,
})

const OrderView = Loadable({
  loader: () => import('./views/orders/OrderView'),
  loading: Loading,
})

const Inputs = Loadable({
  loader: () => import('./ui-kit/src/pages/inputs/InputsPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;
    return <Component {...props} environment={environment}/>
  }
})


const Pipelines = Loadable({
  loader: () => import('./ui-kit/src/pages/pipelines/PipelinesPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;
    return <Component {...props} environment={environment}/>
  }
})

const Images = Loadable({
  loader: () => import('./views/Images/ImagesPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;
    return <Component {...props}/>
  }
})

const MarketingMaterials = Loadable({
  loader: () => import('./ui-kit/src/pages/MarketingMaterials/MarketingMaterialsPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;

    function Wrapper({WrappedComponent, ...props}) {
      const user = useCurrentUser();
      return <WrappedComponent currentBrandId={user.brand.id} {...props}/>
    }

    return <Wrapper WrappedComponent={Component} {...props} environment={environment}/>
  }
})

const MarketingFolder = Loadable({
  loader: () => import('./ui-kit/src/pages/MarketingMaterials/MarketingFolderPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;
    return <Component {...props} environment={environment}/>
  }
})

const AmazonCallback = Loadable({
  loader: () => import('./ui-kit/src/pages/oauth/AmazonCallbackView'),
  loading: Loading,
})

const BolComCallback = Loadable({
  loader: () => import('./ui-kit/src/pages/oauth/BolComCallbackView'),
  loading: Loading,
})

const routes = [
  {path: '/', exact: true, name: 'Home', component: DefaultLayout},
  {path: '/product/catalog', exact: true, name: 'Products', component: ProductsPrototype},
  {path: '/product/catalog/:id', exact: true, name: 'Products', component: ProductView},
  {path: '/product/collections', exact: true, name: 'Collections', component: UploadFile},
  {path: '/orders', exact: true, name: 'Orders', component: Orders},
  {path: '/order/:id', exact: true, name: 'Order', component: OrderView},
  {path: '/distributedata/', exact: true, name: 'Distribute Data', component: Output},
  {path: '/distributedata/distribute', exact: true, name: 'Distribute', component: Output},
  {path: '/output', exact: true, name: 'Distribute Data', component: Output},
  {path: '/distributedata/retailers', exact: true, name: 'Retailers', component: SubscriptionsListV2},
  {path: '/inputs', exact: true, name: "Inputs", component: Inputs},
  {path: '/pipelines', exact: true, name: "Pipelines", component: Pipelines},
  {path: '/images', exact: true, name: 'Images', component: Images},
  {path: '/marketing', exact: true, name: 'Marketing', component: MarketingMaterials},
  {path: '/marketing/folders/:id', exact: true, name: 'Marketing Folder', component: MarketingFolder},

  //oauth return
  {path: '/output', exact: true, name: 'Output', component: Output},

  // old redirects to preserve eventual bookmarks of our clients
  {
    path: '/product/my-products',
    exact: true,
    name: 'Old Products Page',
    component: () => <Redirect to={'/product/catalog'}/>
  },
  {path: '/oauth/callback/amazon/', exact: true, name: 'Amazon Oauth Callback', component: AmazonCallback},
  {path: '/oauth/callback/bol/', exact: true, name: 'Bol Oauth Callback', component: BolComCallback},
];

export default routes;
