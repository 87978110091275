/**
 * File downloading utility. Takes in the url of the file as the arg and triggers a download in the browser.
 * @param sUrl
 * @returns {boolean}
 */
import {itemBufferCount, tableCellHeight} from "../Consts";

/**
 * Helper method to figure out how many items to load at first when we render a list.
 */
export function getListFirstItemCount() {
  return Math.ceil(window.innerHeight / tableCellHeight + itemBufferCount)
}


export function noop() {
}


export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}