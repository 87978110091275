const CHUNK_ERROR_HANDLING_REFRESH_LIMIT = 5
const CHUNK_ERROR_HANDLING_STORAGE_PERSISTENCE = 300000 // 5 minutes
export const REFRESH_COUNT = "chunkErrorHandlingRefreshCount"
export const TIMESTAMP = "chunkErrorHandlingTimestamp"

type HandleChunkErrorsProps = {
  error: {
    name: string
  } | null
}

type IsChunkErrorProps = {
  error: {
    name: string
  } | null,
  refreshCount: number
}

export function isChunkError({error, refreshCount}: IsChunkErrorProps): boolean {
  return !!(error && error.name === 'ChunkLoadError' && refreshCount < CHUNK_ERROR_HANDLING_REFRESH_LIMIT)
}

function isPersistenceLimitElapsed(): boolean {
  return new Date().getTime() - parseInt(localStorage.getItem(TIMESTAMP) as string) > CHUNK_ERROR_HANDLING_STORAGE_PERSISTENCE
}

export function handleChunkErrors({error}: HandleChunkErrorsProps): void {
  let refreshCount = localStorage.getItem(REFRESH_COUNT) ? parseInt(localStorage.getItem(REFRESH_COUNT) as string) : 0

  if (isChunkError({error, refreshCount})) {
    localStorage.setItem(TIMESTAMP, String(new Date().getTime()))
    localStorage.setItem(REFRESH_COUNT, String(refreshCount + 1))
    window.location.reload()
  }

  if (localStorage.getItem(TIMESTAMP) && isPersistenceLimitElapsed()) {
    localStorage.removeItem(TIMESTAMP)
    localStorage.removeItem(REFRESH_COUNT)
  }
}
