import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";
import {UpdateBrandMutation} from "./__generated__/UpdateBrandMutation.graphql";


const mutation = graphql`
  mutation UpdateBrandMutation($input: UpdateBrandMutationInput!) {
    updateBrand(input: $input) {
      brand {
        id
        name
        logo
      }
    }
  }
`;

export default createMutationBoilerplate<UpdateBrandMutation>(mutation);
