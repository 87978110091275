import React from 'react';
import {Redirect} from "react-router-dom";

export default function AmazonCallbackView() {
  let queryParams = new URLSearchParams(window.location.search);

  // this endpoint is called due to a redirected from Amazon's login
  // and sending us the information in the query parameters
  // https://developer-docs.amazon.com/sp-api/docs/website-authorization-workflow#step-3-amazon-sends-you-the-authorization-information
  let data = JSON.stringify({
    state: queryParams.get('state'),
    selling_partner_id: queryParams.get('selling_partner_id'),
    spapi_oauth_code: queryParams.get('spapi_oauth_code'),
    marketplace: queryParams.get('marketplace'),
    vendorCode: queryParams.get('vendorCode')
  })

  return <Redirect to={`/output?continueFlow=amazon&data=${data}`}/>
}
