import styles from "./Dropdown.module.scss";
import SearchInput from "./SearchInput";
import React, {InputHTMLAttributes} from "react";

interface OptionsFilterProps extends InputHTMLAttributes<HTMLInputElement> {
  search: string
  setSearch: (s: string) => void
  delay?: number
}

export function OptionsFilter({search, setSearch, delay = 0}: OptionsFilterProps) {
  return <div className={styles.filter}>
    <SearchInput
      value={search}
      autoFocus
      onChange={e => {
        setSearch(e.target.value);
      }}
      placeholder={'Search'}
      delay={delay}
    />
  </div>
}
