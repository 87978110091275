import actionTypes from "../actionTypes";
import {addToArray, ensureNoDuplicates, removeFromArray} from "../common/arrayCommons";


let initialState = {
  visibleProducts: [],  // a list of {id: '...', unified: [unifiedProducts]}
  productCount: 0,
  selectAllVisibleIsChecked: false,  // precomputed value to see if the select all from page checkbox should be checked or not

  allSelected: false,

  selectedUnifiedProducts: [],
  allSelectedIgnoredUnifiedProducts: [],
};

export function allVisibleProductsAreSelected(state) {
  if (state.allSelected) {
    // is true when no unified product Id from visibleProducts is in allSelectedIgnoredUnifiedProducts
    return state.visibleProducts.map(product => product.unifiedproductSet.edges.filter(edge => state.allSelectedIgnoredUnifiedProducts.indexOf(edge.node.id) !== -1)).flat().length === 0;
  } else {
    // is true when all unified products Ids from visibleProducts are in selectedUnifiedProducts
    return state.visibleProducts.map(product => product.unifiedproductSet.edges.filter(edge => state.selectedUnifiedProducts.indexOf(edge.node.id) === -1)).flat().length === 0;
  }
}

function getVisibleUnifiedProductIds(state) {
  return state.visibleProducts.map(item => item.unifiedproductSet.edges.map(e => e.node.id)).flat();
}

function getAllSelectedState(allSelected) {
  return {
    allSelected: allSelected,
    selectedUnifiedProducts: [],
    allSelectedIgnoredUnifiedProducts: []
  };
}

//case functions

const caseFunctions = {
  [actionTypes.SELECT_PRODUCT]: selectProduct,
  [actionTypes.SELECT_PRODUCTS_FROM_ORDER_CONFIRMATION]: selectProductsFromOrderConfirmation,
  [actionTypes.DESELECT_PRODUCT]: deselectProduct,
  [actionTypes.SELECT_ALL]: selectAll,
  [actionTypes.DESELECT_ALL]: deselectAll,
  [actionTypes.SELECT_VISIBLE]: selectVisible,
  [actionTypes.DESELECT_VISIBLE]: deselectVisible,
  [actionTypes.SET_VISIBLE_PRODUCTS]: setVisibleProducts,
}

function selectProduct(action, state, newState) {
  if (state.allSelected) {
    Object.assign(newState, state, {
      allSelectedIgnoredUnifiedProducts: removeFromArray(state.allSelectedIgnoredUnifiedProducts, action.unifiedProductIds),
    });
  } else {
    Object.assign(newState, state, {
      selectedUnifiedProducts: addToArray(state.selectedUnifiedProducts, action.unifiedProductIds),
    });
  }
}

function selectProductsFromOrderConfirmation(action, state, newState) {
  Object.assign(newState, state, {
    allSelected: false,
    allSelectedIgnoredUnifiedProducts: [],
    selectedUnifiedProducts: action.unified,
  });
}

function deselectProduct(action, state, newState) {
  if (state.allSelected) {
    Object.assign(newState, state, {
      allSelectedIgnoredUnifiedProducts: addToArray(state.allSelectedIgnoredUnifiedProducts, action.unifiedProductIds)
    });
  } else {
    Object.assign(newState, state, {
      selectedUnifiedProducts: removeFromArray(state.selectedUnifiedProducts, action.unifiedProductIds)
    });
  }
}

function selectAll(action, state, newState) {
  Object.assign(newState, state, getAllSelectedState(true));
}

function deselectAll(action, state, newState) {
  Object.assign(newState, state, getAllSelectedState(false));
}

function selectVisible(action, state, newState) {
  if (state.allSelected) {
    Object.assign(newState, state, {
      allSelectedIgnoredUnifiedProducts: removeFromArray(
        state.allSelectedIgnoredUnifiedProducts,
        getVisibleUnifiedProductIds(state)
      )
    })
  } else {
    Object.assign(newState, state, {
      selectedUnifiedProducts: addToArray(state.selectedUnifiedProducts, getVisibleUnifiedProductIds(state)),
    });
  }
}

function deselectVisible(action, state, newState) {
  if (state.allSelected) {
    Object.assign(newState, state, {
      allSelectedIgnoredUnifiedProducts: addToArray(state.allSelectedIgnoredUnifiedProducts, getVisibleUnifiedProductIds(state))
    })
  } else {
    Object.assign(newState, state, {
      selectedUnifiedProducts: removeFromArray(state.selectedUnifiedProducts, getVisibleUnifiedProductIds(state)),
    });
  }
}

function setVisibleProducts(action, state, newState) {
  Object.assign(newState, state, {
    visibleProducts: action.products,
    productCount: action.totalCount
  })
}

function productsReducer(state = initialState, action) {
  let newState = {};

  if (Object.keys(caseFunctions).indexOf(action.type) > -1) {
    caseFunctions[action.type](action, state, newState)
  } else {
    return state
  }

  Object.assign(newState, {
    selectAllVisibleIsChecked: allVisibleProductsAreSelected(newState),
    selectedUnifiedProducts: ensureNoDuplicates(newState.selectedUnifiedProducts),
    allSelectedIgnoredUnifiedProducts: ensureNoDuplicates(newState.allSelectedIgnoredUnifiedProducts)
  });
  return newState;
}


export default productsReducer;



