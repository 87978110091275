/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateCompanyUserMutationInput = {
    email: string;
    phoneNumber: string;
    password: string;
    repeatPassword: string;
    scope: string;
    name: string;
    vat: string;
    address: string;
    city: string;
    postNumber: string;
    termsChecked: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateCompanyUserMutationVariables = {
    input: CreateCompanyUserMutationInput;
};
export type CreateCompanyUserMutationResponse = {
    readonly createCompanyUser: {
        readonly user: {
            readonly id: string;
            readonly email: string;
            readonly brandSet: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly name: string;
                    } | null;
                } | null>;
            } | null;
        } | null;
        readonly company: {
            readonly id: string;
            readonly name: string;
        } | null;
    } | null;
};
export type CreateCompanyUserMutation = {
    readonly response: CreateCompanyUserMutationResponse;
    readonly variables: CreateCompanyUserMutationVariables;
};



/*
mutation CreateCompanyUserMutation(
  $input: CreateCompanyUserMutationInput!
) {
  createCompanyUser(input: $input) {
    user {
      id
      email
      brandSet {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    company {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCompanyUserMutationPayload",
    "kind": "LinkedField",
    "name": "createCompanyUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandNodeConnection",
            "kind": "LinkedField",
            "name": "brandSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyNode",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCompanyUserMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCompanyUserMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "92fa5a4afe97d53147f56888e19a6dc5",
    "id": null,
    "metadata": {},
    "name": "CreateCompanyUserMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCompanyUserMutation(\n  $input: CreateCompanyUserMutationInput!\n) {\n  createCompanyUser(input: $input) {\n    user {\n      id\n      email\n      brandSet {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n    company {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5c7a2388ba97414ebbc4bdc30597f517';
export default node;
