import React from "react";
import UserSettingsDropdown from "../../../ui-kit/src/specialized/navigation/UserSettingsDropdown";
import BrandsNavbarSettingsDropdown from "./BrandsNavbar/BrandsNavbarSettingsDropdown";

function UserCombinedMenu({
                            name,
                            email,
                            displaySettings,
                            handleSettings,
                            hasChangeDetailsPermission,
                            hasManageUsersPermission,
                            handleLogOut,
                            handleAddCompany, // not implemented in retailers
                            canAddCompany,
                            toggleManageApiKeysModal,
                            companies,
                            currentCompany,
                            handleCompanyChange,
                            onUserManagementClick, //not implemented
                            onCompanyManagementClick, // not implemented
                            isRetailer
                          }) {
  return <div className={'d-flex'}>
    <BrandsNavbarSettingsDropdown currentCompany={currentCompany} companies={companies}
                                  handleCompanyChange={handleCompanyChange} canAddCompany={canAddCompany}
                                  handleAddCompany={handleAddCompany} toggleManageApiKeysModal={toggleManageApiKeysModal}/>
    <UserSettingsDropdown userName={name} userEmail={email} handleSettings={handleSettings}
                          handleLogOut={handleLogOut}/>
  </div>
}

export default UserCombinedMenu;
