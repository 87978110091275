import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";
import {ChangeCurrentBrandMutation} from "./__generated__/ChangeCurrentBrandMutation.graphql";


const mutation = graphql`
  mutation ChangeCurrentBrandMutation($input: UserSetCurrentBrandMutationInput!) {
    setUserCurrentBrand(input: $input) {
      user {
        id
      }
    }
  }
`;

export default createMutationBoilerplate<ChangeCurrentBrandMutation>(mutation);