import React from 'react';
import Dropdown, {Option} from "../../../../ui-kit/src/atoms/Dropdown";
import {BrandsNavbarSettingsDropdownProps} from "./types";
import Button from "../../../../ui-kit/src/atoms/Button";
import styles from "./BrandsNavbarSettingsDropdown.module.scss";
import addClassNames from "../../../../ui-kit/src/classNameUtils";

const BRANDS_DROPDOWN_OPTIONS = {
  "ADD_BRAND": "add_brand",
  "MANAGE_API_KEYS": "manage_api_keys",
  "MANAGE_BRAND": "brand_management"
}

export default function BrandsNavbarSettingsDropdown({currentCompany, companies, handleCompanyChange, toggleManageApiKeysModal, canAddCompany, handleAddCompany}: BrandsNavbarSettingsDropdownProps) {
  const dropdownOptions: Option[]  = [
    ...companies
      .sort((c1, c2) => {
        if (c1.name === currentCompany.name) {
          return -1;
        } else if (c2.name === currentCompany.name) {
          return 1;
        } else {
          return c1.name.localeCompare(c2.name);
        }
      })
      .map(company => ({
        label: <label className={`mb-0 ${styles.smallFont}`} style={{cursor: "inherit"}}>{company.name}</label>,
        value: company.id,
        searchBase: company.name,
        icon: currentCompany.id === company.id ?
          <i className={`fa-regular fa-circle-dot ${styles.dropdownActiveIcon}`}/> :
          <i className={`fa-regular fa-circle ${styles.dropdownInactiveIcon}`}/>
      })),
    {
      divider: true
    },
    {
      label: <label className={`mb-0 ${styles.smallFont}`} style={{cursor: "inherit"}}>Add a brand</label>,
      value: BRANDS_DROPDOWN_OPTIONS.ADD_BRAND,
      icon: <i className={`fa-regular fa-circle-plus ${styles.dropdownItemIcon}`}/>,
      disabled: !canAddCompany,
      className: styles.dropdownOptionHover
    },
    {
      label: <label className={`mb-0 ${styles.smallFont}`} style={{cursor: "inherit"}}>Manage API keys</label>,
      value: BRANDS_DROPDOWN_OPTIONS.MANAGE_API_KEYS,
      icon: <i className={`fa-regular fa-key ${styles.dropdownItemIcon}`}/>,
      className: styles.dropdownOptionHover
    },
    {
      label: <label className={`mb-0 ${styles.smallFont}`} style={{cursor: "inherit"}}>Brand management</label>,
      value: BRANDS_DROPDOWN_OPTIONS.MANAGE_BRAND,
      icon: <i className={`fa-regular fa-industry ${styles.dropdownItemIcon}`} style={{marginRight: '0.563rem'}}/>,
      disabled: true
    },
  ]

  return <div className={styles.dropdownContainer}>
    <Dropdown currentValue={null} options={dropdownOptions}
              onSelectOption={op => {
                if (op.value === BRANDS_DROPDOWN_OPTIONS.ADD_BRAND) {
                  handleAddCompany();
                } else if (op.value === BRANDS_DROPDOWN_OPTIONS.MANAGE_BRAND) {
                  // not implemented
                } else if(op.value === BRANDS_DROPDOWN_OPTIONS.MANAGE_API_KEYS) {
                  toggleManageApiKeysModal();
                } else if (op.value) {
                  handleCompanyChange({id: op.value})
                }
              }}
              ToggleTag={(props) => {
                return <Button onClick={e => props.toggleDropdowns(e)}
                               className={addClassNames([
                                 {className: styles.dropdownMenuButton, condition: true},
                                 {className: styles.dropdownMenuButtonActive, condition: props.isOpen},
                               ])}
                               color={'transparentSecondary'}>
                  <i className={`fa-regular fa-industry ${styles.userIcon} ${props.isOpen && styles.userIconActive}`}/>
                  <p className={"mb-0 " + styles.smallFont}>
                    {currentCompany.name}
                  </p>
                  <i className={`ml-2 mr-0 fa-solid fa-chevron-down ${styles.chevronIcon}`}/>
                </Button>
              }}/>
  </div>
}
