// This function is automatically applied to the local Environments.
// First argument is a config found in each project's Constants'
// Second and third are the operation name(query name) and error to be ignored

export function isErrorIgnored(
  ignoredErrorsConfig: { [key: string]: string [] },
  operationName: string,
  errors: { message: string } []) {

  if (!Object.keys(ignoredErrorsConfig).includes(operationName)) {
    return false
  }
  let isOnlyIgnoredErrors = true
  for (let error of errors) {
    if (!ignoredErrorsConfig[operationName].includes(error.message)) {
      isOnlyIgnoredErrors = false
    }
  }
  return isOnlyIgnoredErrors
}
