import {createMutationBoilerplate} from "../commons/graphql";
import {graphql} from "react-relay";

export default createMutationBoilerplate(
  graphql`
    mutation ChangePasswordWithTokenMutation($input: ChangePasswordWithTokenInput!) {
      changePasswordWithToken(input: $input) {
        success
      }
    }
  `
)
