/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LoginRequiredContainerQueryVariables = {};
export type LoginRequiredContainerQueryResponse = {
    readonly currentUser: {
        readonly id: string;
        readonly company: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
        readonly avatar: string | null;
        readonly brand: {
            readonly id: string;
            readonly name: string;
            readonly logo: string | null;
            readonly pendingDataSubscriptionRequests: number | null;
            readonly hasPermissions: {
                readonly manageBrandUsers: boolean | null;
                readonly changeBrand: boolean | null;
            } | null;
        } | null;
        readonly brandSet: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                } | null;
            } | null>;
        } | null;
        readonly activeFeatureFlags: ReadonlyArray<string>;
        readonly isSystemAdmin: boolean | null;
    } | null;
};
export type LoginRequiredContainerQuery = {
    readonly response: LoginRequiredContainerQueryResponse;
    readonly variables: LoginRequiredContainerQueryVariables;
};



/*
query LoginRequiredContainerQuery {
  currentUser {
    id
    company {
      id
      name
    }
    firstName
    lastName
    email
    avatar
    brand {
      id
      name
      logo
      pendingDataSubscriptionRequests
      hasPermissions {
        manageBrandUsers
        changeBrand
      }
    }
    brandSet {
      edges {
        node {
          id
          name
        }
      }
    }
    activeFeatureFlags
    isSystemAdmin
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyNode",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandNode",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingDataSubscriptionRequests",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandPermissions",
            "kind": "LinkedField",
            "name": "hasPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "manageBrandUsers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changeBrand",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandNodeConnection",
        "kind": "LinkedField",
        "name": "brandSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activeFeatureFlags",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSystemAdmin",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginRequiredContainerQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginRequiredContainerQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "39a79ea9904bb6df9b4f1881f1773fc8",
    "id": null,
    "metadata": {},
    "name": "LoginRequiredContainerQuery",
    "operationKind": "query",
    "text": "query LoginRequiredContainerQuery {\n  currentUser {\n    id\n    company {\n      id\n      name\n    }\n    firstName\n    lastName\n    email\n    avatar\n    brand {\n      id\n      name\n      logo\n      pendingDataSubscriptionRequests\n      hasPermissions {\n        manageBrandUsers\n        changeBrand\n      }\n    }\n    brandSet {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    activeFeatureFlags\n    isSystemAdmin\n  }\n}\n"
  }
};
})();
(node as any).hash = '9cbf05d71a14a7b2ad6c0371d7ec67f6';
export default node;
